import useSWR, { Fetcher, Key } from "swr";
import {
  AnchorWallet,
  useConnection,
  useWallet,
  WalletContextState,
} from "@solana/wallet-adapter-react";
import {
  AccumulatorAdapter,
  AccumulatorConfig,
  AnchorProvider,
} from "player2-solana-sdk";
import { Connection, PublicKey } from "@solana/web3.js";
import addresses from "../../models/btcaAddresses";
import { AccumulatorType } from "../../models/accumulatorType";

type ProgramKeyType = [Key, AccumulatorType, WalletContextState, Connection];

const programKey: Key = "program";
const accumulatorConfig: { [key in AccumulatorType]: AccumulatorConfig } = {
  [AccumulatorType.BTC]: {
    poolPubkey: new PublicKey(addresses.btcaPool),
    rewardMintPubkey: new PublicKey(addresses.rewardMint),
    treasuryMintPubkey: new PublicKey(addresses.treasuryMint),
    accumulators: [
      new PublicKey(addresses.btca1Mint),
      new PublicKey(addresses.btca2Mint),
      new PublicKey(addresses.btca3Mint),
    ],
  },
    // TODO: Change ETH AccumulatorConfig
  [AccumulatorType.ETH]: {
    poolPubkey: new PublicKey(addresses.btcaPool),
    rewardMintPubkey: new PublicKey(addresses.rewardMint),
    treasuryMintPubkey: new PublicKey(addresses.treasuryMint),
    accumulators: [
      new PublicKey(addresses.btca1Mint),
      new PublicKey(addresses.btca2Mint),
      new PublicKey(addresses.btca3Mint),
    ],
  },
};

/* Fetches accumulator information from the chain via SDK. */
const programFetcher: Fetcher<AccumulatorAdapter, ProgramKeyType> = async (
  _key,
  accumulatorType,
  wallet,
  connection
) => {
  const provider = new AnchorProvider(
    connection,
    wallet as AnchorWallet,
    AnchorProvider.defaultOptions()
  );

  return await new AccumulatorAdapter(
    provider,
    accumulatorConfig[accumulatorType]
  ).build();
};

const useProgram = (accumulatorType = AccumulatorType.BTC) => {
  const wallet = useWallet();
  const { connection } = useConnection();

  const { data } = useSWR(
    wallet?.connected && connection ? [programKey, accumulatorType] : null,
    (...args) => programFetcher(...args, wallet, connection),
    {
      dedupingInterval: 86400000, // do not refresh for a day
    }
  );

  return { program: data };
};

export default useProgram;
