import "./Home.css";

import React, { FC, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { useWallet } from "@solana/wallet-adapter-react";

import dashboardUILabel from "./assets/Dashboard_label_ui.png";
import dashboardUI from "./assets/Dashboard_UI_btn.png";
import dashboardUIHover from "./assets/Dashboard_UI_Hover.png";
import homeBgAnimation from "./assets/Home-bg-animation.gif";
import homeBg from "./assets/Home-bg.png";
import accumulator from "./assets/NPCs/Accumulator/Animation/Accumulator_Idle_FullAnimation.gif";
import accumulatorTag from "./assets/NPCs/Accumulator/label.png";
import accumulatorHover from "./assets/NPCs/Accumulator/Static/Accumulator_hover_outline_ui.png";
import bargainHunter from "./assets/NPCs/Bargain Hunter/Animation/Hunter_Idle_FullAnimation.gif";
import bargainHunterTag from "./assets/NPCs/Bargain Hunter/label.png";
import bargainHunterHover from "./assets/NPCs/Bargain Hunter/Static/Hunter_hover_outline_ui.png";
import provider from "./assets/NPCs/Provider/Animation/Provider_Idle_Loop.gif";
import providerTag from "./assets/NPCs/Provider/label.png";
import providerHover from "./assets/NPCs/Provider/Static/Provider_hover_outline_ui.png";
import trader from "./assets/NPCs/Trader/Animation/Trader_Idle_Loop.gif";
import traderTag from "./assets/NPCs/Trader/label.png";
import traderHover from "./assets/NPCs/Trader/Static/Trader_hover_outline_ui.png";
import wise from "./assets/NPCs/Wise/Animation/Wise_Idle_FullAnimation.gif";
import wiseTag from "./assets/NPCs/Wise/label.png";
import wiseHover from "./assets/NPCs/Wise/Static/Wise_hover_outline_ui.png";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";

const Home: FC = () => {
  const [backgroundHeight, setbackgroundHeight] = useState(
    Math.min(
      window.innerWidth > 800
        ? window.innerWidth * 0.44
        : window.innerWidth * 0.8,
      window.innerHeight * 0.8
    )
  );
  const backgroundHeightWidthRatio = 974 / 994;
  const backgroundWidth = Math.min(
    Math.round(window.innerHeight * 0.8 * backgroundHeightWidthRatio),
    window.innerWidth > 800 ? window.innerWidth * 0.44 : window.innerWidth * 0.9
  );
  const tagHeight = (35 / 740) * backgroundHeight;
  useEffect(() => {
    function handleResize() {
      setbackgroundHeight(Math.round(window.innerHeight * 0.8));
    }
    window.addEventListener("resize", handleResize);
  });

  const [dialogue, setDialogue] = useState(dialogues["info_center/welcome"]);
  const { connected } = useWallet();

  return (
    <div className="flex flex-col items-center justify-center py-8 min-h-screen">
      {dialogue ? (
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
      ) : (
        []
      )}
      <img
        alt=""
        src={homeBg}
        className="absolute z-0 pointer-events-none"
        style={{
          height: `${backgroundHeight}px`,
          width: `${backgroundWidth}px`,
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
        }}
      />
      <img
        alt=""
        src={homeBgAnimation}
        className="absolute z-0 pointer-events-none"
        style={{
          height: `${backgroundHeight}px`,
          width: `${backgroundWidth}px`,
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
        }}
      />
      {!connected ? (
        <button
          onClick={() => setDialogue(dialogues["dashboard/not_ready"])}
          className="w-fit-content absolute z-20"
          style={{
            height: `${backgroundHeight * 0.14}px`,
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -${backgroundHeight * 0.55}px)`,
          }}
        >
          <div className="w-full flex justify-center relative">
            <img
              alt=""
              src={dashboardUILabel}
              className="hover absolute z-30"
              style={{ height: `${tagHeight}px`, top: `-${tagHeight}px` }}
            />
          </div>
          <img
            alt=""
            src={dashboardUI}
            className="cursor-pointer"
            style={{ height: "100%", width: "auto" }}
            onMouseOver={(e) =>
              ((e.target as HTMLImageElement).src = dashboardUIHover)
            }
            onMouseOut={(e) =>
              ((e.target as HTMLImageElement).src = dashboardUI)
            }
          />
        </button>
      ) : (
        <Link
          to={"/dashboard"}
          className="w-fit-content absolute z-20"
          style={{
            height: `${backgroundHeight * 0.14}px`,
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -${backgroundHeight * 0.55}px)`,
          }}
        >
          <div className="w-full flex justify-center relative">
            <img
              alt=""
              src={dashboardUILabel}
              className="hover absolute z-30"
              style={{ height: `${tagHeight}px`, top: `-${tagHeight}px` }}
            />
          </div>
          <img
            alt=""
            src={dashboardUI}
            className="cursor-pointer"
            style={{ height: "100%", width: "auto" }}
            onMouseOver={(e) =>
              ((e.target as HTMLImageElement).src = dashboardUIHover)
            }
            onMouseOut={(e) =>
              ((e.target as HTMLImageElement).src = dashboardUI)
            }
          />
        </Link>
      )}

      <div
        className="absolute z-10"
        style={{
          height: `${backgroundHeight}px`,
          width: `${backgroundWidth}px`,
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
        }}
      >
        {!connected ? (
          <button
            onClick={() => setDialogue(dialogues["liquidity_pools/not_ready"])}
            className="absolute flex justify-center flex-col cursor-pointer"
            style={{
              height: `${backgroundHeight * 0.0953}px`,
              minWidth: `${backgroundWidth * 0.0973}px`,
              transform: `translate(-${backgroundWidth * 0.35}px, -${
                backgroundHeight * 0.36
              }px)`,
              left: "50%",
              top: "50%",
            }}
          >
            <img
              alt=""
              src={providerTag}
              className="z-30 hover"
              style={{ minHeight: `${tagHeight}px` }}
            />
            <img
              alt=""
              src={provider}
              className="mx-auto"
              style={{
                height: `${(60 / 756) * backgroundHeight}px`,
                width: "fit-content",
              }}
              onMouseOver={(e) =>
                ((e.target as HTMLImageElement).src = providerHover)
              }
              onMouseOut={(e) =>
                ((e.target as HTMLImageElement).src = provider)
              }
            />
          </button>
        ) : (
          <Link
            to="/liquidity_pools"
            className="absolute flex justify-center flex-col cursor-pointer"
            style={{
              height: `${backgroundHeight * 0.0953}px`,
              minWidth: `${backgroundWidth * 0.0973}px`,
              transform: `translate(-${backgroundWidth * 0.35}px, -${
                backgroundHeight * 0.36
              }px)`,
              left: "50%",
              top: "50%",
            }}
          >
            <img
              alt=""
              src={providerTag}
              className="z-30 hover"
              style={{ minHeight: `${tagHeight}px` }}
            />
            <img
              alt=""
              src={provider}
              className="mx-auto"
              style={{
                height: `${(60 / 756) * backgroundHeight}px`,
                width: "fit-content",
              }}
              onMouseOver={(e) =>
                ((e.target as HTMLImageElement).src = providerHover)
              }
              onMouseOut={(e) =>
                ((e.target as HTMLImageElement).src = provider)
              }
            />
          </Link>
        )}
        <Link
          to="/info_center"
          className="absolute flex justify-center flex-col cursor-pointer"
          style={{
            height: `-${backgroundHeight * 0.44}px`,
            minWidth: `${backgroundWidth * 0.1}px`,
            transform: `translate(-${backgroundWidth * 0.45}px, ${
              backgroundHeight * 0.05
            }px)`,
            left: "50%",
            top: "50%",
          }}
        >
          <img
            alt=""
            src={wiseTag}
            className="z-30 hover"
            style={{ height: `${tagHeight}px` }}
          />
          <img
            alt=""
            src={wise}
            className="mx-auto"
            style={{
              height: `${(60 / 756) * backgroundHeight}px`,
              width: "fit-content",
            }}
            onMouseOver={(e) =>
              ((e.target as HTMLImageElement).src = wiseHover)
            }
            onMouseOut={(e) => ((e.target as HTMLImageElement).src = wise)}
          />
        </Link>
        {!connected ? (
          <button
            onClick={() => setDialogue(dialogues["trader/not_ready"])}
            className="absolute flex justify-center flex-col cursor-pointer"
            style={{
              height: `-${backgroundHeight * 0.44}px`,
              minWidth: `${backgroundWidth * 0.1}px`,
              transform: `translate(${backgroundWidth * 0.16}px, -${
                backgroundHeight * 0.24
              }px)`,
              left: "50%",
              top: "50%",
            }}
          >
            <img
              alt=""
              src={traderTag}
              className="z-30 hover"
              style={{ height: `${tagHeight}px` }}
            />
            <img
              alt=""
              src={trader}
              className="mx-auto"
              style={{
                height: `${(77 / 756) * backgroundHeight}px`,
                width: "fit-content",
              }}
              onMouseOver={(e) =>
                ((e.target as HTMLImageElement).src = traderHover)
              }
              onMouseOut={(e) => ((e.target as HTMLImageElement).src = trader)}
            />
          </button>
        ) : (
          <Link
            to="/swap"
            className="absolute flex justify-center flex-col cursor-pointer"
            style={{
              height: `-${backgroundHeight * 0.44}px`,
              minWidth: `${backgroundWidth * 0.1}px`,
              transform: `translate(${backgroundWidth * 0.16}px, -${
                backgroundHeight * 0.24
              }px)`,
              left: "50%",
              top: "50%",
            }}
          >
            <img
              alt=""
              src={traderTag}
              className="z-30 hover"
              style={{ height: `${tagHeight}px` }}
            />
            <img
              alt=""
              src={trader}
              className="mx-auto"
              style={{
                height: `${(77 / 756) * backgroundHeight}px`,
                width: "fit-content",
              }}
              onMouseOver={(e) =>
                ((e.target as HTMLImageElement).src = traderHover)
              }
              onMouseOut={(e) => ((e.target as HTMLImageElement).src = trader)}
            />
          </Link>
        )}
        {!connected ? (
          <button
            onClick={() => setDialogue(dialogues["bonds/not_ready"])}
            className="absolute flex justify-center flex-col cursor-pointer"
            style={{
              height: `-${backgroundHeight * 0.44}px`,
              minWidth: `${backgroundWidth * 0.1}px`,
              transform: `translate(${backgroundWidth * 0.01}px, ${
                backgroundHeight * 0.135
              }px)`,
              left: "50%",
              top: "50%",
            }}
          >
            <img
              alt=""
              src={bargainHunterTag}
              className="z-30 hover"
              style={{ height: `${tagHeight}px` }}
            />
            <img
              alt=""
              src={bargainHunter}
              className="mx-auto"
              style={{
                height: `${(80 / 756) * backgroundHeight}px`,
                width: "fit-content",
              }}
              onMouseOver={(e) =>
                ((e.target as HTMLImageElement).src = bargainHunterHover)
              }
              onMouseOut={(e) =>
                ((e.target as HTMLImageElement).src = bargainHunter)
              }
            />
          </button>
        ) : (
          <Link
            to="/bonds"
            className="absolute flex justify-center flex-col cursor-pointer"
            style={{
              height: `-${backgroundHeight * 0.44}px`,
              minWidth: `${backgroundWidth * 0.1}px`,
              transform: `translate(${backgroundWidth * 0.01}px, ${
                backgroundHeight * 0.135
              }px)`,
              left: "50%",
              top: "50%",
            }}
          >
            <img
              alt=""
              src={bargainHunterTag}
              className="z-30 hover"
              style={{ height: `${tagHeight}px` }}
            />
            <img
              alt=""
              src={bargainHunter}
              className="mx-auto"
              style={{
                height: `${(80 / 756) * backgroundHeight}px`,
                width: "fit-content",
              }}
              onMouseOver={(e) =>
                ((e.target as HTMLImageElement).src = bargainHunterHover)
              }
              onMouseOut={(e) =>
                ((e.target as HTMLImageElement).src = bargainHunter)
              }
            />
          </Link>
        )}
        {!connected ? (
          <button
            onClick={() => setDialogue(dialogues["accumulators/not_ready"])}
            className="absolute flex justify-center flex-col cursor-pointer"
            style={{
              height: `-${backgroundHeight * 0.44}px`,
              minWidth: `${backgroundWidth * 0.1}px`,
              transform: `translate(${backgroundWidth * 0.325}px, ${
                backgroundHeight * 0.118
              }px)`,
              left: "50%",
              top: "50%",
            }}
          >
            <img
              alt=""
              src={accumulatorTag}
              className="z-30 hover"
              style={{ height: `${tagHeight}px` }}
            />
            <img
              alt=""
              src={accumulator}
              className="mx-auto"
              style={{
                height: `${(80 / 756) * backgroundHeight}px`,
                width: "fit-content",
              }}
              onMouseOver={(e) =>
                ((e.target as HTMLImageElement).src = accumulatorHover)
              }
              onMouseOut={(e) =>
                ((e.target as HTMLImageElement).src = accumulator)
              }
            />
          </button>
        ) : (
          <Link
            to="/accumulators"
            className="absolute flex justify-center flex-col cursor-pointer"
            style={{
              height: `-${backgroundHeight * 0.44}px`,
              minWidth: `${backgroundWidth * 0.1}px`,
              transform: `translate(${backgroundWidth * 0.325}px, ${
                backgroundHeight * 0.118
              }px)`,
              left: "50%",
              top: "50%",
            }}
          >
            <img
              alt=""
              src={accumulatorTag}
              className="z-30 hover"
              style={{ height: `${tagHeight}px` }}
            />
            <img
              alt=""
              src={accumulator}
              className="mx-auto"
              style={{
                height: `${(80 / 756) * backgroundHeight}px`,
                width: "fit-content",
              }}
              onMouseOver={(e) =>
                ((e.target as HTMLImageElement).src = accumulatorHover)
              }
              onMouseOut={(e) =>
                ((e.target as HTMLImageElement).src = accumulator)
              }
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default Home;
