import React, { useState, memo, useEffect, FC } from "react";
import TypeIt from "typeit-react";
import "./Portrait.css";

const Dialogue: FC<{ persona: string; message: JSX.Element[] }> = ({
  persona,
  message,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    return () => {
      setShow(true);
    };
  }, [persona, message]);
  if (!persona || !message) {
    return null;
  }

  return (
    <div
      className="fixed max-w-[95%] z-20 p-4 text-sm flex justify-between rounded space-x-8 drop-shadow-2xl dialogue select-none"
      style={{
        transform: "translateX(-50%) translateY(0%)",
        left: "50%",
        display: `${show ? "block" : "none"}`,
        bottom: "2%",
      }}
    >
      <div
        className={`relative flex bg-gray-backgroundMid portrait justify-center text-center 
        ${persona.toLowerCase().split(" ")[0]}`}
      >
        <div className="absolute flex items-center justify-center shadow-xl drop-shadow text-xs portrait-name bottom-0 px-4">
          {persona.split(" ")[0]}
        </div>
      </div>
      <button
        className="absolute right-6 top-6 text-xl"
        onClick={() => setShow(false)}
      >
        x
      </button>
      <div
        className="flex flex-col text-2xl absolute"
        style={{
          left: `${window.innerWidth >= 800 ? "14%" : "25%"}`,
          top: "10%",
          maxHeight: "70%",
          maxWidth: "75%",
        }}
      >
        <div className="rounded-xl flex flex-col px-4 py-4 font-pixolletta overflow-y-scroll scrollbar-hide">
          <TypeIt
            options={{
              speed: -25,
              waitUntilVisible: true,
              afterStep: (instance: any) => {
                instance.getElement().parentElement.scrollTop =
                  instance.getElement().clientHeight;
              },
              afterComplete: (instance: any) => {
                instance.getElement().parentElement.scrollTop =
                  instance.getElement().clientHeight;
              },
            }}
            key={Math.random()}
          >
            {message.map((message: JSX.Element) => (
              <span key={Math.random()}>{message}</span>
            ))}
          </TypeIt>
        </div>
      </div>
    </div>
  );
};

export default memo(Dialogue);
