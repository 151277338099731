import { FC, useCallback, useState } from "react";

import { useParams } from "react-router-dom";

import { Liquidity, Token, TokenAmount } from "@raydium-io/raydium-sdk";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import Dialogue from "./Dialogue";
import { currencyFormatter, wrBtcFormatter } from "./helpers/StringFormatters";
import HoverSelector from "./HoverSelector";
import addresses from "./models/btcaAddresses";
import dialogues from "./models/dialogues";
import PageLayout from "./PageLayout";
import { inputValidator } from "./helpers/InputValidator";
import Loading from "./Loading";
import React from "react";
import useLiquidityPoolInfo, {
  wrBtcUsdcPoolKeys,
} from "./fetchers/pools/useLiquidityPoolInfo";
import useTokenBalances from "./fetchers/tokens/useTokenBalances";
import { AccumulatorType } from "./models/accumulatorType";

const LiquidityPool: FC = () => {
  const { pair } = useParams();

  const { data: liquidityPoolData } = useLiquidityPoolInfo(AccumulatorType.BTC);
  const { data: tokenBalancesData } = useTokenBalances();

  // const [usdcInput, setUsdcInput] = useState(0);
  const [wrBtcInput, setWrBtcInput] = useState(0);
  const { sendTransaction, publicKey } = useWallet();
  const { connection } = useConnection();
  const [dialogue, setDialogue] = useState(dialogues["liquidity_pool/add"]);

  // const setUsdcInputToMax = () => {
  //   setUsdcInput(accountBalances.tokens[addresses.usdcMint]);
  // };

  const addLiquidity = useCallback(async () => {
    if (!connection || !publicKey || !tokenBalancesData || !liquidityPoolData) {
      return;
    }

    setDialogue(dialogues["liquidity_pools/pending"]);

    const { transaction } = await Liquidity.makeAddLiquidityTransaction({
      connection,
      poolKeys: wrBtcUsdcPoolKeys,
      userKeys: {
        tokenAccounts: tokenBalancesData?.rawInfos,
        owner: publicKey,
        payer: publicKey,
      },
      amountInA: new TokenAmount(
        new Token(addresses.rewardMint, 6, "wrBTC", "wrBTC"),
        wrBtcInput * 1000000
      ),
      amountInB: new TokenAmount(
        new Token(addresses.usdcMint, 6, "USDC", "USDC"),
        parseInt(
          (wrBtcInput * liquidityPoolData.price * 1000000 * 1.01).toFixed(0)
        )
      ),
      fixedSide: "a",
    });

    try {
      let signature = await sendTransaction(transaction, connection);

      await connection.confirmTransaction(signature, "processed").then(() => {
        setDialogue(dialogues["liquidity_pool/add/success"]);
        setWrBtcInput(0);
      });
    } catch (e) {
      setDialogue(dialogues["liquidity_pool/add/failure"]);
    }
  }, [
    connection,
    publicKey,
    tokenBalancesData,
    liquidityPoolData,
    wrBtcInput,
    sendTransaction,
  ]);

  if (!liquidityPoolData || !tokenBalancesData) {
    return <Loading title="Add Liquidity" />;
  }

  const setWrBtcInputToMax = () => {
    setWrBtcInput(
      tokenBalancesData?.formattedInfos[addresses.rewardMint].uiAmount
    );
  };

  return (
    <PageLayout title={"Add Liquidity"}>
      <div className="flex flex-col">
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div
          className={
            "flex-col justify-center items-center space-y-4 flex-1 mb-4"
          }
        >
          <div className="flex w-full">
            <div className="flex-1 items-center text-center flex flex-col space-y-2">
              <div className="table-header">Pair</div>
              <div className="text-lg">{pair}</div>
            </div>
            <div className="flex-1 items-center text-center flex flex-col space-y-2">
              <div className="table-header">wrBTC Liquidity</div>
              <div className="text-lg">
                {currencyFormatter(liquidityPoolData.liquidity)}
                {/* <div className="text-sm text-gray-300 mt-1">{liquidityPoolInfo.totalLiquidityVolume}</div> */}
              </div>
            </div>
            <div className="flex-1 items-center text-center flex flex-col space-y-2">
              <div className="table-header">APR 7D</div>
              <div className="text-lg">{liquidityPoolData.apy}</div>
            </div>
            <div className="flex-1 items-center text-center flex flex-col space-y-2">
              <div className="table-header">Fees 7D</div>
              <div className="text-lg">
                {currencyFormatter(liquidityPoolData.fee_7d)}
              </div>
            </div>
          </div>
        </div>

        <div className={"flex-col items-center space-y-6 flex-1 my-4 "}>
          <div className="flex  items-center w-full">
            <div className="flex items-center space-x-8">
              <div className="text-white rounded-full bg-gray-backgroundDark w-20 h-20 flex items-center justify-center">
                50%
              </div>
              <div>wrBTC</div>
            </div>

            <div className="flex justify-end relative flex-1">
              <div
                className="absolute top-0 z-2 text-black rounded-md px-3 py-1 text-sm"
                style={{ transform: "translateY(-100%)" }}
              >{`Available ${wrBtcFormatter(
                tokenBalancesData.formattedInfos[addresses.rewardMint].uiAmount
              )}`}</div>
              <label
                htmlFor="mintBondInput"
                className="flex items-center justify-end relative w-input"
              >
                <input
                  type="number"
                  step="0.0001"
                  value={wrBtcInput}
                  id="mintBondInput"
                  className="w-full rounded-lg h-14 focus:outline-0 p-4"
                  placeholder="Amount"
                  onChange={(e) => {
                    const amount = inputValidator(
                      e.target.valueAsNumber,
                      tokenBalancesData.formattedInfos[addresses.rewardMint]
                        .uiAmount,
                      6
                    );

                    setWrBtcInput(amount);
                  }}
                />
                <button
                  onClick={() => setWrBtcInputToMax()}
                  className="absolute right-1 sm-btn z-1 hover:text-white"
                >
                  Max
                </button>
              </label>
            </div>
          </div>

          <div className="flex  items-center w-full">
            <div className="flex items-center space-x-8">
              <div className="text-white rounded-full bg-gray-backgroundDark w-20 h-20 flex items-center justify-center">
                50%
              </div>
              <div>USDC</div>
            </div>

            <div className="flex justify-end relative flex-1">
              <div
                className="absolute top-0 z-2 text-black rounded-md px-3 py-1 text-sm"
                style={{ transform: "translateY(-100%)" }}
              >{`Available ${currencyFormatter(
                tokenBalancesData.formattedInfos[addresses.usdcMint].uiAmount
              )}`}</div>
              <label
                htmlFor="mintBondInput"
                className="flex items-center justify-end relative w-input"
              >
                <input
                  type="number"
                  step="0.0001"
                  value={inputValidator(
                    wrBtcInput * liquidityPoolData.price,
                    wrBtcInput * liquidityPoolData.price,
                    6
                  )}
                  id="mintBondInput"
                  className="w-full rounded-lg h-14 focus:outline-0 p-4"
                  placeholder="Amount"
                  readOnly
                />
                {/* <button
                  onClick={() => setUsdcInputToMax()}
                  className="absolute right-1 z-1 sm-btn hover:text-white"
                >
                  Max
                </button> */}
                <span className="absolute right-4">(estimated)</span>
              </label>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              onClick={addLiquidity}
              className="long-btn hover:text-white"
            >
              Add Liquidity
            </button>
          </div>
        </div>

        <div className="my-4 flex-col">
          <HoverSelector to={`/liquidity_pools/${pair}`}>Back</HoverSelector>
        </div>
      </div>
    </PageLayout>
  );
};

export default LiquidityPool;
