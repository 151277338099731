import "./App.css";
import "./Wallet.css";
import Home from "./Home";
import Dashboard from "./Dashboard";
import Bonds from "./Bonds";
import InfoCenter from "./InfoCenter";
import Bond from "./Bond";
import BondMint from "./BondMint";
import BondRedeem from "./BondRedeem";
import LiquidityPools from "./LiquidityPools";
import LiquidityPool from "./LiquidityPool";
import LiquidityPoolAdd from "./LiquidityPoolAdd";
import LiquidityPoolRemove from "./LiquidityPoolRemove";
import Swap from "./Swap";
import Accumulators from "./Accumulators";
import Accumulator from "./Accumulator";
import React, { FC, useCallback, useEffect } from "react";

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import logo from "./assets/logo_P2_black.png";

import WalletContext from "./context/WalletContext";
import JupiterContext from "./context/JupiterContext";

const App: FC = () => {
  const navigate = useNavigate();
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        navigate("/");
      }
    },
    [navigate]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <WalletContext>
      <div>
        <div className="wallet">
          <WalletMultiButton />
        </div>

        <a href="https://player2.world/" className="fixed top-4 left-4 z-50">
          <img src={logo} className="h-24" alt="" />
        </a>

        <ReactTooltip />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/bonds" element={<Bonds />} />
          <Route path="/info_center" element={<InfoCenter />} />

          <Route path="/bonds/:bondName" element={<Bond />} />
          <Route path="/bonds/:bondName/mint" element={<BondMint />} />
          <Route path="/bonds/:bondName/redeem" element={<BondRedeem />} />

          <Route path="/liquidity_pools" element={<LiquidityPools />} />
          <Route path="/liquidity_pools/:pair" element={<LiquidityPool />} />
          <Route
            path="/liquidity_pools/:pair/add"
            element={<LiquidityPoolAdd />}
          />
          <Route
            path="/liquidity_pools/:pair/remove"
            element={<LiquidityPoolRemove />}
          />
          <Route
            path="/swap"
            element={
              <JupiterContext>
                <Swap />
              </JupiterContext>
            }
          />
          <Route path="/accumulators" element={<Accumulators />} />
          <Route path="/accumulators/:accumulator/:index" element={<Accumulator />} />
        </Routes>
      </div>
    </WalletContext>
  );
};

export default App;
