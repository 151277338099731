import PageLayout from "./PageLayout";
import { useParams } from "react-router-dom";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";
import { ReactComponent as Arrow } from "./assets/arrow.svg";
import { Link } from "react-router-dom";
import HoverSelector from "./HoverSelector";
import { FC } from "react";
import { currencyFormatter } from "./helpers/StringFormatters";
import Loading from "./Loading";
import React from "react";
import useLiquidityPoolInfo from "./fetchers/pools/useLiquidityPoolInfo";
import { AccumulatorType } from "./models/accumulatorType";

const LiquidityPool: FC = () => {
  const { pair } = useParams();
  const { data: liquidityPoolData } =useLiquidityPoolInfo(AccumulatorType.BTC);
  const dialogue = dialogues["liquidity_pool"];

  if (!liquidityPoolData) {
    return <Loading title={pair ?? ""} />;
  }

  return (
    <PageLayout title={pair ?? ""}>
      <div className="flex flex-col">
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div className={"flex-col justify-center items-center flex py-4"}>
          <div className="flex w-full">
            <div className="flex-1  items-center justify-center flex flex-col space-y-2">
              <div className="text-white font-pixolletta text-lg font-bold tracking-widest">
                Pair
              </div>
              <div>{pair}</div>
            </div>
            <div className="flex-1 items-center  justify-center flex flex-col space-y-2">
              <div className="text-white font-pixolletta text-lg font-bold tracking-widest">
                Liquidity
              </div>
              <div>{currencyFormatter(liquidityPoolData.liquidity)}</div>
            </div>
            <div className="flex-1 items-center flex flex-col space-y-2">
              <div className="text-white font-pixolletta text-lg font-bold tracking-widest">
                APR 7D
              </div>
              <div>{liquidityPoolData.apy}</div>
            </div>
            <div className="flex-1 items-center flex flex-col space-y-2">
              <div className="text-white font-pixolletta text-lg font-bold tracking-widest">
                Fees 7D
              </div>
              <div>{currencyFormatter(liquidityPoolData.fee_7d)}</div>
            </div>
          </div>
        </div>

        <div className="my-4 flex-col">
          <Link
            to={`/liquidity_pools/${pair}/add`}
            className="select-btn h-12 flex text-lg items-center hover-selector cursor-pointer hover:font-bold"
          >
            <Arrow className={`w-8`} />
            Add
          </Link>

          <Link
            to={`/liquidity_pools/${pair}/remove`}
            className="select-btn h-12 flex text-lg items-center hover-selector cursor-pointer hover:font-bold"
          >
            <Arrow className={`w-8`} />
            Remove
          </Link>
        </div>
        <div className="my-2 flex-col">
          <HoverSelector to={`/liquidity_pools`}>Back</HoverSelector>
        </div>
      </div>
    </PageLayout>
  );
};

export default LiquidityPool;
