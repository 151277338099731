import useSWR, { Fetcher, Key } from "swr";
import {
  useConnection,
  useWallet,
  WalletContextState,
} from "@solana/wallet-adapter-react";
import { Connection, PublicKey } from "@solana/web3.js";
import { SPL_ACCOUNT_LAYOUT, TOKEN_PROGRAM_ID } from "@raydium-io/raydium-sdk";
import BN from "bn.js";
import tokensInfo from "../../models/tokensInfo";

type TokenFormattedInfoType = {
  [k: string]: { uiAmount: number; symbol: string };
};
type TokenBalanceType = {
  rawInfos: Array<{
    pubkey: PublicKey;
    accountInfo: {
      owner: PublicKey;
      state: number;
      mint: PublicKey;
      amount: BN;
      delegateOption: number;
      delegate: PublicKey;
      isNativeOption: number;
      isNative: BN;
      delegatedAmount: BN;
      closeAuthorityOption: number;
      closeAuthority: PublicKey;
    };
  }>;
  formattedInfos: TokenFormattedInfoType;
};

type tokenBalanceKeyType = [Key, Connection, WalletContextState];
const tokenBalanceKey: Key = "token/balances";

/* Fetches accumulator information from the chain via SDK. */
const tokenBalanceFetcher: Fetcher<
  TokenBalanceType,
  tokenBalanceKeyType
> = async (_key, connection, wallet) => {
  const tokenResp = await connection.getTokenAccountsByOwner(
    wallet.publicKey as PublicKey,
    {
      programId: TOKEN_PROGRAM_ID,
    }
  );
  const rawInfos = [];
  const formattedInfos: TokenFormattedInfoType = {};

  for (const { pubkey, account } of tokenResp.value) {
    // double check layout length
    if (account.data.length !== SPL_ACCOUNT_LAYOUT.span) {
      console.warn("invalid token account layout length");
    }

    const rawResult = SPL_ACCOUNT_LAYOUT.decode(account.data);

    const mintAddress = rawResult.mint.toString();
    if (tokensInfo[mintAddress]) {
      formattedInfos[mintAddress] = {
        uiAmount:
          rawResult.amount.toNumber() / 10 ** tokensInfo[mintAddress].decimals,
        symbol: tokensInfo[mintAddress].symbol,
      };
    }

    rawInfos.push({ pubkey, accountInfo: rawResult });
  }

  const solBalance =
    (await connection.getBalance(wallet.publicKey as PublicKey)) / 10 ** 9;
  const solMintAddress = "So11111111111111111111111111111111111111112";
  formattedInfos[solMintAddress] = {
    uiAmount: solBalance,
    symbol: tokensInfo[solMintAddress].symbol,
  };

  // Insert 0 as balance for tokens we track that users do not have token address for
  Object.keys(tokensInfo).forEach((address) => {
    if (!formattedInfos[address]) {
      formattedInfos[address] = {
        uiAmount: 0,
        symbol: tokensInfo[address].symbol,
      };
    }
  });

  return {
    rawInfos: rawInfos,
    formattedInfos,
  };
};

const useTokenBalances = () => {
  const wallet = useWallet();
  const { connection } = useConnection();

  return useSWR(
    wallet.connected && connection ? tokenBalanceKey : null,
    (tokenBalanceKey) =>
      tokenBalanceFetcher(tokenBalanceKey, connection, wallet),
    {
      dedupingInterval: 60000,
    }
  );
};

export default useTokenBalances;
