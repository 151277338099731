import useSWR, { Fetcher, Key } from "swr";
import { AccumulatorType } from "../../models/accumulatorType";

type tokenInfoType = {
  id: string;
  symbol: string;
  name: string;
  image: string;
  current_price: number;
  market_cap: number;
  market_cap_rank: number;
  fully_diluted_valuation: number;
  total_volume: number;
  high_24h: number;
  low_24h: number;
  price_change_24h: number;
  price_change_percentage_24h: number;
  market_cap_change_24h: number;
  market_cap_change_percentage_24h: number;
  circulating_supply: number;
  total_supply: number;
  max_supply: number;
  ath: number;
  ath_change_percentage: number;
  ath_date: string;
  atl: number;
  atl_change_percentage: number;
  atl_date: string;
  roi?: number;
  last_updated: string;
};

type tokenInfoKeyType = [Key, AccumulatorType];

const tokenInfoKey: Key = "tokens/info";
const tokenInfoUrl: { [key in AccumulatorType]: string } = {
  [AccumulatorType.BTC]:
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin",
  [AccumulatorType.ETH]:
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum",
};

/* Fetches WrBtcUsdcPool information from Raydium's API */
const tokenInfoFetcher: Fetcher<tokenInfoType, tokenInfoKeyType> = async (
  _key,
  accumulatorType
) =>
  fetch(tokenInfoUrl[accumulatorType])
    .then((response) => response.json())
    .then(([data]) => data);

const useTokenInfo = (accumulatorType: AccumulatorType) =>
  useSWR([tokenInfoKey, accumulatorType], tokenInfoFetcher);

export default useTokenInfo;
