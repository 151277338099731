import { getPlatformFeeAccounts, PlatformFeeAndAccounts } from "@jup-ag/core";
import { JupiterProvider } from "@jup-ag/react-hook";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import React, { FC, ReactNode, useEffect, useState } from "react";

const JupiterContext: FC<{ children: ReactNode }> = ({ children }) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const [platformFeeAndAccounts, setPlatformFeeAndAccounts] =
    useState<PlatformFeeAndAccounts>();

  useEffect(() => {
    const getPlatformFees = async () => {
      const feeAccounts = await getPlatformFeeAccounts(
        connection,
        new PublicKey("AKEbJFyQrxHSamLJQ9hq6gUtaw7bn8Kc27KixfBFLF21")
      );
      
      setPlatformFeeAndAccounts({
        feeBps: 50,
        feeAccounts,
      });
    };

    getPlatformFees();
  }, [connection]);

  return (
    // @ts-ignore
    <JupiterProvider
      cluster="mainnet-beta"
      connection={connection}
      userPublicKey={wallet.publicKey || undefined}
      platformFeeAndAccounts={platformFeeAndAccounts}
    >
      {children}
    </JupiterProvider>
  );
};

export default JupiterContext;
