const tokensInfo: {
  [k: string]: {
    symbol: string;
    decimals: number;
  };
} =
  !process.env["RPC_NETWORK"] || process.env["RPC_NETWORK"] === "mainnet-beta"
    ? {
        wrBTCqVjkpqktbqN3CeGVSzQ9PFiPonHN98uwEpwMsy: {
          symbol: "wrBTC",
          decimals: 6,
        },
        "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E": {
          symbol: "BTC",
          decimals: 6,
        },
        BTCA1FcBrsmLJLQVNUHwVkmC2PywfK2Qsw3Lb1kFMqGz: {
          symbol: "BTCA1",
          decimals: 6,
        },
        BTCA2PAfYbqPyeRjMPQdUx6c9yTxQhQL6StdfQjmxmjh: {
          symbol: "BTCA2",
          decimals: 6,
        },
        BTCA3ZWcWJjW6qcijPSCePZ7WPCgrYm8KJJNVZJzqHd6: {
          symbol: "BTCA3",
          decimals: 6,
        },
        EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v: {
          symbol: "USDC",
          decimals: 6,
        },
        "3d1oXrdZATm2vcZ44tUtBmWcAXPziWmZfxbsVt9smHkC": {
          symbol: "wrBTC-USDC",
          decimals: 6,
        },
        mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So: {
          symbol: "mSOL",
          decimals: 6,
        },
        "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i": {
          symbol: "UST",
          decimals: 6,
        },
        "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT": {
          symbol: "UXD",
          decimals: 6,
        },
        SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag: {
          symbol: "SUNNY",
          decimals: 6,
        },
        Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1: {
          symbol: "SBR",
          decimals: 6,
        },
        "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs": {
          symbol: "ETH",
          decimals: 8,
        },
        So11111111111111111111111111111111111111112: {
          symbol: "SOL",
          decimals: 9,
        },
      }
    : {
        "8XKoJZazCL5EVaSK3Vr7dkkS9cMPpdinr2mu1kxJagys": {
          symbol: "wrBTC",
          decimals: 6,
        },
        HQ1zB4ft4RHgvFWAgvgks5vjZRX2i486cfBNnbuzPNxV: {
          symbol: "BTC",
          decimals: 6,
        },
        C9kzZDDMpvSHPvahcZFH7y6oVhYQKMN2H6E1uVCczdZU: {
          symbol: "BTCA1",
          decimals: 6,
        },
        "3M4QPB369MdYodDkdEnutZhTSJbFUp93szQHQwHYQzD1": {
          symbol: "BTCA2",
          decimals: 6,
        },
        AMtPJ3N1BAyywN9gvnVszHDmsCthDYysSb3FSEh5PN6V: {
          symbol: "BTCA3",
          decimals: 6,
        },
        EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v: {
          symbol: "USDC",
          decimals: 6,
        },
        "3d1oXrdZATm2vcZ44tUtBmWcAXPziWmZfxbsVt9smHkC": {
          symbol: "wrBTC-USDC",
          decimals: 6,
        },
        mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So: {
          symbol: "mSOL",
          decimals: 6,
        },
        "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i": {
          symbol: "UST",
          decimals: 6,
        },
        "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT": {
          symbol: "UXD",
          decimals: 6,
        },
        SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag: {
          symbol: "SUNNY",
          decimals: 6,
        },
        Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1: {
          symbol: "SBR",
          decimals: 6,
        },
        "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs": {
          symbol: "ETH",
          decimals: 8,
        },
        So11111111111111111111111111111111111111112: {
          symbol: "SOL",
          decimals: 9,
        },
      };

export default tokensInfo;
