import React, { FC } from "react";
import PageLayout from "./PageLayout";
import { Link } from "react-router-dom";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";
import { useState } from "react";
import { currencyFormatter } from "./helpers/StringFormatters";
import Loading from "./Loading";
import useLiquidityPoolInfo, {
  LiquidityPoolInfoType,
} from "./fetchers/pools/useLiquidityPoolInfo";
import { AccumulatorType } from "./models/accumulatorType";

const DefaultPools = () => [
  // { pair: "BTC-wrBTC", liquidity: `${Math.floor(Math.random() * 100)} BTC`, value: "$1,250,568", apr: "268.8%" },
  {
    pair: "wrBTC-USDC",
    liquidity: "3.2942 BTC",
    value: "$450,581",
    apr: "581.1%",
  },
  // { pair: "wrBTC-BTCA1", liquidity: "10.8192 BTC", value: "$581,781", apr: "23.5%" },
  // { pair: "wrBTC-BTCA2", liquidity: "2.2941 BTC", value: "$325,891", apr: "58.2%" },
  // { pair: "wrBTC-BTCA3", liquidity: "8.8192 BTC", value: "$981,735", apr: "18.6%" },
];

const Pool: FC<{
  pool: LiquidityPoolInfoType;
}> = ({ pool }) => {
  return (
    <div className="flex justify-between  w-full items-center">
      <div className="w-44 flex justify-center items-center">{pool.name}</div>
      <div className="w-48 justify-center flex flex-col items-center">
        {currencyFormatter(pool.liquidity)}
        {/* <br />
        <span className="text-gray-300 text-sm">{pool.value}</span> */}
      </div>
      <div className="flex w-32 justify-center items-center">{`${pool.apy}%`}</div>
      <div className="flex w-32 justify-center items-center">
        {currencyFormatter(pool.fee_7d)}
      </div>
      <div className="flex w-32 items-center justify-end">
        <Link
          to={`/liquidity_pools/${pool.name}`}
          className="med-btn flex justify-center items-center py-2 hover:text-white"
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default function LiquidityPools() {
  const [dialogue] = useState(dialogues["liquidity_pools"]);
  const { data: liquidityPoolData } = useLiquidityPoolInfo(AccumulatorType.BTC);

  if (!liquidityPoolData) {
    return <Loading title="Liquidity Pools" />;
  }

  return (
    <PageLayout title={"Liquidity Pools"}>
      <div className="flex relative">
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div
          className={"flex-col justify-center items-center space-y-4 flex-1"}
        >
          <div className="flex w-full justify-between">
            <div className="w-44 table-header flex justify-center">Pair</div>
            <div className="w-48 table-header flex justify-center">
              Liquidity
            </div>
            <div className="w-32 table-header flex justify-center">APR 7D</div>
            <div className="w-32 table-header flex justify-center">Fees 7D</div>
            <div className="w-32 table-header"></div>
          </div>
          {[liquidityPoolData].map((pool) => {
            return <Pool pool={pool} key={pool.name} />;
          })}
        </div>
      </div>
    </PageLayout>
  );
}
