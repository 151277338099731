import PageLayout from "./PageLayout";
import { useParams } from "react-router-dom";
import HoverSelector from "./HoverSelector";
import { FC } from "react";
import Loading from "./Loading";
import { currencyFormatter } from "./helpers/StringFormatters";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import addresses from "./models/btcaAddresses";
import { useState } from "react";
import { inputValidator } from "./helpers/InputValidator";
import { useCallback } from "react";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";
import { Liquidity, Token, TokenAmount } from "@raydium-io/raydium-sdk";
import useLiquidityPoolInfo, {
  wrBtcUsdcPoolKeys,
} from "./fetchers/pools/useLiquidityPoolInfo";
import React from "react";
import useTokenBalances from "./fetchers/tokens/useTokenBalances";
import { AccumulatorType } from "./models/accumulatorType";

const LiquidityPool: FC = () => {
  const { pair } = useParams();
  const { sendTransaction, publicKey } = useWallet();
  const { connection } = useConnection();
  const [wrBtcUsdcLpInput, setWrBtcUsdcLpInput] = useState(0);

  const [dialogue, setDialogue] = useState(dialogues["liquidity_pool/remove"]);

  const { data: liquidityPoolData } =useLiquidityPoolInfo(AccumulatorType.BTC);
  const { data: tokenBalancesData, mutate: refreshTokenBalancesData } =
    useTokenBalances();

  const removeLiquidity = useCallback(async () => {
    if (!connection || !publicKey || !tokenBalancesData) {
      return;
    }

    setDialogue(dialogues["liquidity_pools/pending"]);
    const lpToken = new Token(
      addresses.wrbtcUsdcLpMint,
      6,
      "wrBTC-USDC",
      "wrBTC-USDC LP"
    );
    const { transaction } = await Liquidity.makeRemoveLiquidityTransaction({
      connection,
      poolKeys: wrBtcUsdcPoolKeys,
      userKeys: {
        tokenAccounts: tokenBalancesData?.rawInfos,
        owner: publicKey,
        payer: publicKey,
      },
      amountIn: new TokenAmount(lpToken, wrBtcUsdcLpInput * 1000000),
    });

    try {
      let signature = await sendTransaction(transaction, connection);

      await connection.confirmTransaction(signature, "processed").then(() => {
        setDialogue(dialogues["liquidity_pool/remove/success"]);
        setWrBtcUsdcLpInput(0);
        refreshTokenBalancesData();
      });
    } catch (e) {
      setDialogue(dialogues["liquidity_pool/remove/failure"]);
    }
  }, [
    connection,
    publicKey,
    tokenBalancesData,
    wrBtcUsdcLpInput,
    sendTransaction,
    refreshTokenBalancesData,
  ]);

  if (!liquidityPoolData || !tokenBalancesData || !pair) {
    return <Loading title={pair ?? ""} />;
  }

  const setWrBtcInputToMax = () => {
    setWrBtcUsdcLpInput(
      tokenBalancesData.formattedInfos[addresses.wrbtcUsdcLpMint].uiAmount
    );
  };
  const setWrBtcInputToHalf = () => {
    setWrBtcUsdcLpInput(
      tokenBalancesData.formattedInfos[addresses.wrbtcUsdcLpMint].uiAmount / 2
    );
  };

  return (
    <PageLayout title={pair}>
      <div className="flex  flex-col">
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div
          className={
            "flex-col justify-center items-center space-y-4 flex-1 mb-4"
          }
        >
          <div className="flex w-full">
            <div className="flex-1 text-center items-center flex flex-col space-y-2">
              <div className="table-header">Pair</div>
              <div className="text-lg">{pair}</div>
            </div>
            <div className="flex-1 text-center items-center flex flex-col space-y-2">
              <div className="table-header">Liquidity</div>
              <div className="text-lg">
                {currencyFormatter(liquidityPoolData.liquidity)}
              </div>
            </div>
            <div className="flex-1 text-center items-center flex flex-col space-y-2">
              <div className="table-header">APR 7D</div>
              <div className="text-lg">{liquidityPoolData.apy}</div>
            </div>
            <div className="flex-1 text-center items-center flex flex-col space-y-2">
              <div className="table-header">Fees 7D</div>
              <div className="text-lg">
                {currencyFormatter(liquidityPoolData.fee_7d)}
              </div>
            </div>
          </div>
        </div>

        <div className={"flex-col items-center flex-1 my-4"}>
          <div className="flex items-center pt-8 pb-2">
            <div className="flex px-8 justify-end relative flex-1">
              <div
                className="absolute right-6 top-0 z-2 text-black rounded-md px-3 py-1 text-sm"
                style={{ transform: "translateY(-100%)" }}
              >
                {`Available ${
                  tokenBalancesData.formattedInfos[addresses.wrbtcUsdcLpMint]
                    .uiAmount
                } wrBTC-USDC`}
              </div>
              <label
                htmlFor="mintBondInput"
                className="flex items-center justify-end relative w-full"
              >
                <input
                  type="number"
                  step="0.0001"
                  value={wrBtcUsdcLpInput}
                  id="mintBondInput"
                  className="w-full rounded-lg h-14 focus:outline-0 p-4"
                  placeholder="Amount"
                  onChange={(e) => {
                    const amount = inputValidator(
                      e.target.valueAsNumber,
                      tokenBalancesData.formattedInfos[
                        addresses.wrbtcUsdcLpMint
                      ].uiAmount,
                      6
                    );

                    setWrBtcUsdcLpInput(amount);
                  }}
                />
                <button
                  onClick={setWrBtcInputToHalf}
                  className="absolute right-0 z-1 sm-btn"
                  style={{ transform: "translateX(-110%)" }}
                >
                  Half
                </button>
                <button
                  onClick={setWrBtcInputToMax}
                  className="absolute right-1 z-1 sm-btn"
                >
                  Max
                </button>
              </label>
            </div>
          </div>
          <div className="flex justify-between px-8 my-2 pb-8">
            <div className="flex">Estimated Value</div>
            <div className="flex">{`$${(
              wrBtcUsdcLpInput * liquidityPoolData.lp_price
            ).toFixed(6)}`}</div>
          </div>
          <div className="flex justify-center">
            <button
              className="long-btn mb-8"
              style={{ width: "24em" }}
              onClick={removeLiquidity}
            >
              Remove Liquidity
            </button>
          </div>
        </div>

        <div className="my-4 flex-col">
          <HoverSelector to={`/liquidity_pools/${pair}`}>Back</HoverSelector>
        </div>
      </div>
    </PageLayout>
  );
};

export default LiquidityPool;
