import useSWR, { Fetcher, Key } from "swr";

type treasuryInfoType = {
  lamports: number;
  ownerProgram: string;
  type: string;
  rentEpoch: number;
  executable: boolean;
  account: string;
  tokenInfo: {
    type: string;
    tokenAddress: string;
    name: string;
    symbol: string;
    price: number;
    volume: number;
    authority: string;
    tokenAmount: {
      amount: string;
      decimals: number;
      uiAmount: number;
      uiAmountString: string;
    };
  };
};
type treasuryInfoKeyType = [Key, string];

const btcaTreasuryAddress = "7ge43BnEme2gLPzZs3scEtuFEBwXgdeDUrEr5eH87c3E";
const treasuryInfoKey: Key = "accounts/treasury/info";

/* Fetches treasury information from Solscan's API */
const treasuryInfoFetcher: Fetcher<
  treasuryInfoType,
  treasuryInfoKeyType
> = async (_key, address) =>
  fetch(`https://public-api.solscan.io/account/${address}`).then((response) =>
    response.json()
  );

const useBtcaTreasuryInfo = () =>
  useSWR([treasuryInfoKey, btcaTreasuryAddress], treasuryInfoFetcher);

export { useBtcaTreasuryInfo };
