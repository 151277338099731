import React, { FC } from "react";
import PageLayout from "./PageLayout";
import HoverSelector from "./HoverSelector";
import { useParams } from "react-router-dom";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";
import { useState, useCallback, useEffect } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import info from "./assets/info.png";
import ReactTooltip from "react-tooltip";
import { btcFormatter, currencyFormatter } from "./helpers/StringFormatters";
import { inputValidator } from "./helpers/InputValidator";
import addresses from "./models/btcaAddresses";
import Loading from "./Loading";
import useLiquidityPoolInfo from "./fetchers/pools/useLiquidityPoolInfo";
import useAccumulatorPoolInfo from "./fetchers/pools/useAccumulatorPoolInfo";
import useTokenInfo from "./fetchers/tokens/useTokenInfo";
import useTokenBalances from "./fetchers/tokens/useTokenBalances";
import useAccumulatorBondInfo from "./fetchers/bonds/useAccumulatorBondInfo";
import useProgram from "./fetchers/program/useProgram";
import { AccumulatorType } from "./models/accumulatorType";

const BondMint: FC = () => {
  const { bondName } = useParams();
  const [dialogue, setDialogue] = useState(dialogues["bonds/mint"]);

  const { data: wrBtcUsdcPoolData } = useLiquidityPoolInfo(AccumulatorType.BTC);
  const { data: btcaPoolData } = useAccumulatorPoolInfo(AccumulatorType.BTC);
  const { data: btcInfo } = useTokenInfo(AccumulatorType.BTC);
  const { data: tokenBalances, mutate: refreshTokenBalances } =
    useTokenBalances();
  const { data: wrBtcBondInfo, mutate: refreshWrBtcBondInfo } =
    useAccumulatorBondInfo(AccumulatorType.BTC, 1);

  const btcTokenBalance =
    tokenBalances?.formattedInfos[addresses.treasuryMint].uiAmount ?? 0;
  const wrBtcPriceInUSD = wrBtcUsdcPoolData?.price ?? 0;
  const btcPriceInUSD = btcInfo?.current_price ?? 0;
  const wrBtcBondPriceInBtc =
    (btcaPoolData?.currentPrice.toNumber() ?? 0) / 1000000;
  const roi =
    (wrBtcPriceInUSD / btcPriceInUSD - wrBtcBondPriceInBtc) /
    wrBtcBondPriceInBtc;

  const { program } = useProgram();
  const { sendTransaction } = useWallet();
  const { connection } = useConnection();
  const [mintAmount, setMintAmount] = useState<number>();
  const disabled = mintAmount !== undefined && mintAmount < 0.001;
  const [buttonText, setButtonText] = useState("Mint");
  const minPurchaseableBTC = 0.001;
  const maxPurchaseableBTC = 1;

  const mintWrBtc = useCallback(async () => {
    if (!program || !mintAmount) {
      return;
    }

    try {
      setButtonText("Pending");
      setDialogue(dialogues["bonds/mint/pending"]);
      const tx = await program.purchase(1, mintAmount);
      let signature = await sendTransaction(tx, connection);
      await connection.confirmTransaction(signature, "processed").then(() => {
        setButtonText("Mint");
        setMintAmount(0);
        setDialogue(dialogues["bonds/mint/success"]);
        refreshWrBtcBondInfo();
        refreshTokenBalances();
      });
    } catch (err) {
      setButtonText("Error");
      setTimeout(() => {
        setButtonText("Mint");
      }, 3000);
      setDialogue(dialogues["bonds/mint/failure"]);
    }
  }, [
    connection,
    program,
    sendTransaction,
    mintAmount,
    refreshTokenBalances,
    refreshWrBtcBondInfo,
  ]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (
    !wrBtcPriceInUSD ||
    !btcPriceInUSD ||
    !wrBtcBondPriceInBtc ||
    !tokenBalances ||
    !wrBtcBondInfo
  ) {
    return <Loading title={bondName ?? "wrBTC"} />;
  }

  return (
    <PageLayout title={bondName ?? "wrBTC"}>
      <div
        className={"flex-col w-info-box justify-center items-center space-y-2"}
      >
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div className="text-lg flex items-center">
          <span>wrBTC Bond</span>
          <img
            src={info}
            alt=""
            className="inline h-6 ml-2"
            data-tip={"Bonding will incur a 0.3% fee"}
          />
        </div>
        <div className="flex justify-between">
          <div className="text-center">
            <span className="text-sm">wrBTC Market Price</span> <br />
            <div>{btcFormatter(wrBtcPriceInUSD / btcPriceInUSD)}</div>
            <div>{currencyFormatter(wrBtcPriceInUSD)}</div>
          </div>
          <div className="text-center">
            <span className="text-sm">Bond Price</span> <br />
            <div>{btcFormatter(wrBtcBondPriceInBtc)}</div>
            <div>{currencyFormatter(wrBtcBondPriceInBtc * btcPriceInUSD)}</div>
          </div>
          <div className="text-center">
            <span className="text-sm">ROI </span>
            <br /> {(roi * 100).toFixed(2)}%
          </div>
        </div>
      </div>
      {wrBtcBondInfo.pendingBond > 0 ? (
        <div className="my-10 text-sm text-center">
          You have an active bond. Redeem it fully before minting another bond.
        </div>
      ) : (
        <>
          <div className="my-4 justify-center w-info-box">
            <label
              htmlFor="mintBondInput"
              className="flex items-center justify-center relative"
            >
              <input
                id="mintBondInput"
                type="number"
                className="flex rounded-lg h-10 focus:outline-0 p-2 flex-1 "
                placeholder="Amount"
                max={maxPurchaseableBTC}
                value={mintAmount}
                onChange={(e) => {
                  setMintAmount(
                    inputValidator(
                      isNaN(parseFloat(e.target.value))
                        ? 0
                        : parseFloat(e.target.value),
                      Math.min(btcTokenBalance, maxPurchaseableBTC),
                      7
                    )
                  );
                }}
              />
              <button
                onClick={() => {
                  setMintAmount(
                    Math.min(
                      inputValidator(btcTokenBalance, btcTokenBalance),
                      maxPurchaseableBTC
                    )
                  );
                }}
                className="absolute right-1 sm-btn hover:text-white"
              >
                Max
              </button>
            </label>
          </div>
          <div className="my-4 justify-center  flex-col items-center">
            <div className="flex justify-between space-y-2 ">
              <div>Your Balance</div>
              <div>{btcFormatter(btcTokenBalance, 6)}</div>
            </div>

            <div className="flex justify-between space-y-2 ">
              <div>You Will Get</div>
              <div>
                {mintAmount
                  ? ((mintAmount / wrBtcBondPriceInBtc) * 0.997).toFixed(6)
                  : 0}{" "}
                wrBTC
              </div>
            </div>

            <div className="flex justify-between space-y-2 ">
              <div>Vesting Term</div>
              <div>7 Days</div>
            </div>

            <div className="flex justify-between space-y-2">
              <div>Minimum Purchase</div>
              <div>{btcFormatter(minPurchaseableBTC, 3)}</div>
            </div>

            <div className="flex justify-between space-y-2 ">
              <div>Maximum Purchase</div>
              <div>{btcFormatter(maxPurchaseableBTC, 3)}</div>
            </div>

            <button
              onClick={() => {
                mintWrBtc();
              }}
              disabled={disabled}
              className={
                disabled ? "med-btn mt-6" : "med-btn hover:text-white mt-6"
              }
            >
              {buttonText}
            </button>
          </div>
        </>
      )}

      <div>
        <HoverSelector to={`/bonds/${bondName}`}>Back</HoverSelector>
      </div>
    </PageLayout>
  );
};

export default BondMint;
