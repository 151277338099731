const addresses =
  !process.env["RPC_NETWORK"] || process.env["RPC_NETWORK"] === "mainnet-beta"
    ? {
        btcaPool: "BTCApg7KH6TA9RqfWm8PGKhEEGj9dnLTJwQkTRiW7i8g",
        rewardMint: "wrBTCqVjkpqktbqN3CeGVSzQ9PFiPonHN98uwEpwMsy",
        treasuryMint: "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
        btca1Mint: "BTCA1FcBrsmLJLQVNUHwVkmC2PywfK2Qsw3Lb1kFMqGz",
        btca2Mint: "BTCA2PAfYbqPyeRjMPQdUx6c9yTxQhQL6StdfQjmxmjh",
        btca3Mint: "BTCA3ZWcWJjW6qcijPSCePZ7WPCgrYm8KJJNVZJzqHd6",
        usdcMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        wrbtcUsdcLpMint: "3d1oXrdZATm2vcZ44tUtBmWcAXPziWmZfxbsVt9smHkC",
        mSolMint: "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
        ustMint: "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i",
        uxdMint: "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT",
        sunnyMint: "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag",
        saberMint: "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
        ethMint: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
        solMint: "So11111111111111111111111111111111111111112",
      }
    : {
        btcaPool: "8JiHd3gGZocKv49vm1i3ngmLAHsJ2b5j6cTPk6whyQC1",
        rewardMint: "8XKoJZazCL5EVaSK3Vr7dkkS9cMPpdinr2mu1kxJagys",
        treasuryMint: "HQ1zB4ft4RHgvFWAgvgks5vjZRX2i486cfBNnbuzPNxV",
        btca1Mint: "C9kzZDDMpvSHPvahcZFH7y6oVhYQKMN2H6E1uVCczdZU",
        btca2Mint: "3M4QPB369MdYodDkdEnutZhTSJbFUp93szQHQwHYQzD1",
        btca3Mint: "AMtPJ3N1BAyywN9gvnVszHDmsCthDYysSb3FSEh5PN6V",
        usdcMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        wrbtcUsdcLpMint: "3d1oXrdZATm2vcZ44tUtBmWcAXPziWmZfxbsVt9smHkC",
        mSolMint: "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
        ustMint: "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i",
        uxdMint: "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT",
        sunnyMint: "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag",
        saberMint: "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
        ethMint: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
        solMint: "So11111111111111111111111111111111111111112",
      };

export default addresses;
