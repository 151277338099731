import { DateTime } from "luxon";
import { AccumulatorAdapter } from "player2-solana-sdk";
import useSWR, { Fetcher, Key } from "swr";
import { inputValidator } from "../../helpers/InputValidator";
import { durationFormatter } from "../../helpers/StringFormatters";
import { AccumulatorType } from "../../models/accumulatorType";
import useProgram from "../program/useProgram";

type AccumulatorBondInfoType = {
  timeUntilFullyVested: string;
  pendingBond: number;
  redeemableBond: number;
};

type AccumulatorBondInfoKeyType = [
  Key,
  AccumulatorType,
  number,
  AccumulatorAdapter
];

const accumulatorBondInfoKey: Key = "bonds/info";

/* Fetches wrBtcBond information from chain */
const accumulatorBondInfoFetcher: Fetcher<
  AccumulatorBondInfoType,
  AccumulatorBondInfoKeyType
> = async (_key, _accumulatorType, bondIndex, program) => {
  const bondData: AccumulatorBondInfoType = {
    timeUntilFullyVested: "",
    pendingBond: 0,
    redeemableBond: 0,
  };

  const depositInfo = await program.getDepositReserve(bondIndex);
  if (!depositInfo) {
    // User did not have any bonds
    return bondData;
  }

  const nowInSec = DateTime.now().toSeconds();

  bondData.timeUntilFullyVested = durationFormatter(
    DateTime.fromSeconds(depositInfo.releaseTime).toMillis() -
      DateTime.now().toMillis()
  );

  if (nowInSec >= depositInfo.releaseTime) {
    bondData.pendingBond = bondData.redeemableBond =
      parseFloat(depositInfo.amount.toString()) / 1000000;
  } else {
    const poolInfo = await program.getPool();
    if (poolInfo) {
      const remainingTicks = Math.ceil(
        (depositInfo.releaseTime - depositInfo.lastUpdateTime) /
          poolInfo.releaseTick
      );

      const lockedTicks = Math.ceil(
        (depositInfo.releaseTime - nowInSec) / poolInfo.releaseTick
      );

      const claimableTicks = remainingTicks - lockedTicks;
      const claimableAmountPerTick =
        parseFloat(depositInfo.amount.toString()) / remainingTicks;
      const redeemableBond =
        (claimableAmountPerTick * claimableTicks) / 1000000;
      bondData.redeemableBond = inputValidator(
        redeemableBond,
        redeemableBond,
        6
      );

      const pendingBond = (claimableAmountPerTick * remainingTicks) / 1000000;
      bondData.pendingBond = inputValidator(pendingBond, pendingBond, 6);
    }
  }

  return bondData;
};

const useAccumulatorBondInfo = (
  accumulatorType: AccumulatorType,
  bondIndex: number
) => {
  const { program } = useProgram(accumulatorType);
  return useSWR(
    program ? [accumulatorBondInfoKey, accumulatorType, bondIndex] : null,
    (...args) =>
      accumulatorBondInfoFetcher(...args, program as AccumulatorAdapter),
    {
      dedupingInterval: 5000,
    }
  );
};

export default useAccumulatorBondInfo;
