import React, { FC } from "react";
import PageLayout from "./PageLayout";
import wise from "./assets/NPCs/Wise/Animation/Wise_Idle_FullAnimation.gif";

const Loading: FC<{ title: string }> = ({ title }) => {
  return (
    <PageLayout title={title}>
      <div className={"flex w-info-box justify-center items-center space-y-2"}>
        <img
          alt=""
          src={wise}
          style={{
            height: `50px`,
            width: "fit-content",
          }}
        />
        <div className="pl-8">Please wait...</div>
      </div>
    </PageLayout>
  );
};

export default Loading;
