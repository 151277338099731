const inputValidator = (
  input: number,
  max: number,
  decimalPlaces = 3
): number => {
  let i = input.toString();
  if (max) {
    i = input > max ? max.toString() : i;
  }

  const validatedInput =
    i.indexOf(".") >= 0
      ? i.substring(0, i.indexOf(".")) +
        i.substring(i.indexOf("."), decimalPlaces + 2)
      : i;

  return parseFloat(validatedInput);
};

export { inputValidator };
