import React, { FC } from "react";
import PageLayout from "./PageLayout";
import HoverSelector from "./HoverSelector";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";
import { wrBtcFormatter } from "./helpers/StringFormatters";
import Loading from "./Loading";
import useProgram from "./fetchers/program/useProgram";
import useAccumulatorBondInfo from "./fetchers/bonds/useAccumulatorBondInfo";
import { AccumulatorType } from "./models/accumulatorType";

const BondRedeem: FC = () => {
  const { bondName } = useParams();
  const { sendTransaction } = useWallet();
  const { connection } = useConnection();
  const { program } = useProgram();
  const { data: wrBtcBondInfo, mutate: refreshWrBtcBondInfo } =
    useAccumulatorBondInfo(AccumulatorType.BTC, 1);

  const [buttonText, setButtonText] = useState("Redeem");
  const [dialogue, setDialogue] = useState(dialogues["bonds/redeem"]);

  const claimReward = useCallback(async () => {
    if (!program || !wrBtcBondInfo || wrBtcBondInfo.redeemableBond <= 0) {
      return setDialogue(dialogues["bonds/redeem/no_bonds_to_redeem"]);
    }

    try {
      setDialogue(dialogues["bonds/redeem/pending"]);
      setButtonText("Pending");
      const tx = await program.claimReward(1);
      let signature = await sendTransaction(tx, connection);
      await connection
        .confirmTransaction(signature, "processed")
        .then(() => refreshWrBtcBondInfo())
        .then(() => {
          setDialogue(dialogues["bonds/redeem/success"]);
          setButtonText("Success");
        });
    } catch (err) {
      setDialogue(dialogues["bonds/redeem/failure"]);
      setButtonText("Error");
    }
  }, [
    connection,
    sendTransaction,
    program,
    wrBtcBondInfo,
    refreshWrBtcBondInfo,
  ]);

  if (!wrBtcBondInfo) {
    return <Loading title="Redeem" />;
  }

  return (
    <PageLayout title={"Redeem"}>
      <div className={"flex-col justify-center items-center space-y-2"}>
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div className="">Redeemable Rewards</div>
        <div className="">
          {wrBtcFormatter(wrBtcBondInfo.redeemableBond, 6)}
        </div>
      </div>

      <div className="my-4 justify-center flex-col items-center">
        <div className="flex justify-between ">
          <div>Pending Rewards</div>
          <div>
            {wrBtcBondInfo.pendingBond < 0
              ? wrBtcFormatter(0)
              : wrBtcFormatter(wrBtcBondInfo.pendingBond, 6)}
          </div>
        </div>

        <div className="flex justify-between">
          <div>Time Until Fully Vested</div>
          <div className="text-right">{`${wrBtcBondInfo.timeUntilFullyVested}`}</div>
        </div>
      </div>
      <button
        onClick={claimReward}
        className="hover:text-white med-btn mt-4 mx-auto"
      >
        {buttonText}
      </button>

      <div>
        <HoverSelector to={`/bonds/${bondName}`}>Back</HoverSelector>
      </div>
    </PageLayout>
  );
};

export default BondRedeem;
