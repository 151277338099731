import { ReactComponent as Arrow } from "./assets/arrow.svg";
import { Link } from "react-router-dom";

import "./HoverSelector.css";
import React, { FC, ReactNode } from "react";

const HoverSelector: FC<{
  children: ReactNode;
  to: string;
  active?: boolean;
}> = ({ children, active, to }) => {
  return (
    <Link to={to} className="flex items-center hover-selector cursor-pointer">
      <Arrow className={`w-8 ${active ? "active" : ""} `} />
      {children}
    </Link>
  );
};

HoverSelector.defaultProps = {
  to: "",
  active: false,
};

export default HoverSelector;
