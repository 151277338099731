import useSWR, { Fetcher, Key } from "swr";
import { AccumulatorAdapter } from "player2-solana-sdk";
import useProgram from "../program/useProgram";
import { AccumulatorType } from "../../models/accumulatorType";

type AccumulatorInfoType = {
  index: number;
  releaseDelay: number;
};

type accumulatorInfoKeyType = [
  Key,
  AccumulatorType,
  number,
  AccumulatorAdapter
];
const accumulatorInfoKey: Key = "accumulators/info";

/* Fetches accumulator information from the chain via SDK. */
const accumulatorInfoFetcher: Fetcher<
  AccumulatorInfoType,
  accumulatorInfoKeyType
> = async (_key, _accumulatorType, index, program) => {
  const { indexFee, releaseDelay } = await program.getAccumulator(index);

  return {
    index: indexFee.basisPoints / 10000,
    releaseDelay: releaseDelay ?? 0 / 60,
  };
};

const useAccumulatorInfo = (
  accumulatorType: AccumulatorType,
  index: number
) => {
  const { program } = useProgram(accumulatorType);

  return useSWR(
    program ? [accumulatorInfoKey, accumulatorType, index] : null,
    (...args) => accumulatorInfoFetcher(...args, program as AccumulatorAdapter),
    {
      dedupingInterval: 60000,
    }
  );
};

export default useAccumulatorInfo;
