import PageLayout from "./PageLayout";
import { useParams } from "react-router-dom";
import HoverSelector from "./HoverSelector";
import Dialogue from "./Dialogue";
import { lazy, useCallback, useRef, useState, useEffect, FC } from "react";
import dialogues from "./models/dialogues";
import divider from "./assets/Other UI/divider_ui.png";
import refresh from "./assets/Symbols/swap_blk_symbol.png";
import refreshHover from "./assets/Symbols/swap_wht_symbol.png";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import ReactTooltip from "react-tooltip";
import infoSymbol from "./assets/Symbols/info_symbol.png";
import { Duration } from "luxon";
import { inputValidator } from "./helpers/InputValidator";
import Loading from "./Loading";
import useAccumulatorsAPR from "./fetchers/accumulators/useAccumulatorsAPR";
import useAccumulatorInfo from "./fetchers/accumulators/useAccumulatorInfo";
import { accumulatorDowngradeInfoKey } from "./fetchers/accumulators/useAccumulatorDowngradeInfo";
import useTokenBalances from "./fetchers/tokens/useTokenBalances";
import useProgram from "./fetchers/program/useProgram";
import { Suspense } from "react";
import { mutate } from "swr";
import React from "react";
import { AccumulatorType } from "./models/accumulatorType";
import accumulatorTokenAddresses from "./models/accumulatorTokenAddresses";
import tokensInfo from "./models/tokensInfo";

const AccumulatorDowngrade = lazy(() => import("./AccumulatorDowngrade"));

const Accumulator: FC = () => {
  const { accumulator, index } = useParams<{
    accumulator: AccumulatorType;
    index: string;
  }>();

  if (!accumulator || !index) {
    throw new Error("no params");
  }
  const accumulatorTokenSymbol = `${accumulator}A${index}`;
  const tokenIndex = parseInt(index);
  const { bondMint } = accumulatorTokenAddresses[accumulator];
  const bondSymbol = tokensInfo[bondMint].symbol;

  const { sendTransaction } = useWallet();
  const { connection } = useConnection();

  /* Migration to SWR */
  const { program } = useProgram(accumulator);
  const { data: info } = useAccumulatorInfo(accumulator, tokenIndex);
  const { data: aprData, mutate: refreshAprData } =
    useAccumulatorsAPR(accumulator);

  if (aprData && Date.now() - aprData?.lastFetchedTime > 8.64e7) {
    refreshAprData();
  }

  const { data: tokenBalances, mutate: refreshTokenBalances } =
    useTokenBalances();

  const [isUpgrade, setIsUpgrade] = useState(true);
  const [dialogue, setDialogue] = useState(
    (dialogues as any)[`accumulators/${index}`]
  );

  const refreshEl = useRef<HTMLImageElement>(null);

  const upgradeDowngrade = () => {
    setIsUpgrade(!isUpgrade);
    refreshEl.current?.classList.add("animate-spin");
    setTimeout(() => {
      refreshEl.current?.classList.remove("animate-spin");
    }, 500);
    !isUpgrade ? setButtonText("Upgrade") : setButtonText("Downgrade");
    !isUpgrade
      ? setDialogue(dialogues["accumulators/upgrade"])
      : setDialogue(dialogues["accumulators/downgrade"]);
    setChangeAmount(0);
  };

  const [changeAmount, setChangeAmount] = useState(0);
  const [buttonText, setButtonText] = useState("Upgrade");

  const makeChange = useCallback(async () => {
    if (!program) {
      return;
    }

    setButtonText("Pending");
    setDialogue(dialogues["accumulators/change/pending"]);
    try {
      const tx = isUpgrade
        ? await program.upgrade(tokenIndex, changeAmount)
        : await program.downgrade(tokenIndex, changeAmount);

      let signature = await sendTransaction(tx, connection);
      await connection.confirmTransaction(signature, "processed").then(() => {
        setButtonText("Success");
        setChangeAmount(0);
        setDialogue(dialogues["accumulators/change/success"]);
        refreshTokenBalances();
        mutate([accumulatorDowngradeInfoKey, tokenIndex]);
      });
    } catch (err) {
      console.log(err);
      setButtonText("Error");
      setChangeAmount(0);
      setDialogue(dialogues["accumulators/change/failure"]);
    }
  }, [
    connection,
    program,
    sendTransaction,
    changeAmount,
    isUpgrade,
    refreshTokenBalances,
    tokenIndex,
  ]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!program || !info || !aprData || !tokenBalances) {
    return <Loading title={accumulator ?? ""} />;
  }

  const accumulatorAmount =
    tokenBalances.formattedInfos[
      accumulatorTokenAddresses[accumulator][tokenIndex]
    ].uiAmount ?? 0;

  return (
    <PageLayout title={accumulatorTokenSymbol}>
      <div className="flex flex-col">
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div className={"flex-col justify-center items-center space-y-4 mb-4"}>
          <div className="flex justify-between">
            <div className="text-center flex flex-col space-y-2">
              <div className="font-pixolletta text-lg text-white">
                Downgrade Duration
              </div>
              <div className="text-lg">
                {Duration.fromObject({
                  minutes: info.releaseDelay / 60,
                }).as("days")}{" "}
                days
              </div>
            </div>

            <div className="text-center flex flex-col space-y-2">
              <div className="font-pixolletta text-lg text-white">APR</div>
              <div className="text-lg">
                {/* Index starts with 0 instead of 1 */}
                {aprData.indexData[tokenIndex - 1]}
              </div>
              <div className="text-gray-300"></div>
            </div>

            <div className="text-center flex flex-col space-y-2">
              <div className="font-pixolletta text-lg text-white">Index</div>
              <div className="text-lg">{info.index}</div>
            </div>
          </div>
        </div>
        <img src={divider} alt="seperator" className="mb-4" />

        <div className={"flex-col items-center space-y-6 flex-1 my-4 "}>
          <div className="flex items-center w-full">
            <div className="flex flex-col ">
              <div
                className="flex items-center text-sm text-white mb-2 font-pixolletta background-pink-300"
                data-tip={
                  isUpgrade
                    ? "Upgrades will incur a 0.3% fee"
                    : "Downgrades with incur a 0.3% fee "
                }
              >
                <span>{isUpgrade ? "Upgrade" : "Downgrade"}</span>
                <img src={infoSymbol} alt="" className="inline h-5 ml-2" />
              </div>
              <div>
                {isUpgrade
                  ? bondSymbol
                  : accumulatorTokenSymbol}
              </div>
            </div>

            <div className="flex justify-end relative flex-1">
              <div
                className="absolute top-0 z-2 text-black rounded-md px-3 py-1 text-sm"
                style={{ transform: "translateY(-100%)" }}
              >
                {isUpgrade
                  ? `Available ${tokenBalances.formattedInfos[bondMint].uiAmount} ${bondSymbol}`
                  : `Available ${accumulatorAmount} ${accumulatorTokenSymbol}`}
              </div>
              <label
                htmlFor="mintBondInput"
                className="flex items-center justify-end relative w-input"
              >
                <input
                  id="mintBondInput"
                  className="w-full rounded-lg h-12 focus:outline-0 p-4"
                  placeholder="Amount"
                  value={changeAmount}
                  onChange={(e) => {
                    setChangeAmount(e.target.valueAsNumber);
                  }}
                  type="number"
                  step="0.000001"
                  min="0.0001"
                />
                <button
                  onClick={() => {
                    isUpgrade
                      ? setChangeAmount(
                          tokenBalances.formattedInfos[bondMint].uiAmount
                        )
                      : setChangeAmount(accumulatorAmount);
                  }}
                  className="absolute right-0 z-1 sm-btn text-white rounded-md px-3 py-1 cursor-pointer scale-75"
                >
                  Max
                </button>
              </label>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <button
              onClick={() => upgradeDowngrade()}
              className="flex justify-center py-4"
              disabled={changeAmount < 0}
            >
              <img
                alt="refresh"
                ref={refreshEl}
                src={refresh}
                className="h-6"
                onMouseOver={(e) => {
                  (e.target as HTMLInputElement).src = refreshHover;
                }}
                onMouseOut={(e) => {
                  (e.target as HTMLInputElement).src = refresh;
                }}
              />
            </button>
          </div>

          <div className="flex items-center w-full">
            <div className="flex flex-col justify-center">
              <div className="font-pixolletta text-lg text-white mb-2">For</div>
              <div>
                {!isUpgrade
                  ? bondSymbol
                  : accumulatorTokenSymbol}
              </div>
            </div>

            <div className="flex justify-end relative flex-1">
              <div
                className="absolute top-0 z-2 text-black rounded-md px-3 py-1 text-sm"
                style={{ transform: "translateY(-100%)" }}
              >
                {isUpgrade
                  ? `Available ${accumulatorAmount} ${accumulatorTokenSymbol}`
                  : `Available ${tokenBalances.formattedInfos[bondMint].uiAmount} ${bondSymbol}`}
              </div>
              <label
                htmlFor="mintBondInput"
                className="flex items-center justify-end relative w-input"
              >
                <input
                  id="mintBondInput"
                  className="w-full rounded-lg h-12 focus:outline-0 p-4"
                  placeholder="Amount"
                  value={
                    isUpgrade
                      ? inputValidator(
                          (changeAmount / info.index) * 0.997,
                          (changeAmount / info.index) * 0.997,
                          6
                        )
                      : inputValidator(
                          changeAmount * info.index * 0.997,
                          changeAmount * info.index * 0.997,
                          6
                        )
                  }
                  type="number"
                  readOnly
                />
              </label>
            </div>
          </div>

          <div className="flex items-center w-full justify-center">
            <button
              onClick={makeChange}
              className="long-btn text-white rounded-md px-12 py-2 self-end font-pixolletta font-bold text-lg tracking-wider"
              disabled={changeAmount <= 0}
            >
              {buttonText}
            </button>
          </div>
        </div>

        <Suspense fallback={null}>
          <AccumulatorDowngrade
            accumulatorType={accumulator}
            tokenIndex={tokenIndex}
            setDialogue={setDialogue}
          />
        </Suspense>

        <div className="my-2 flex-col">
          <HoverSelector to={`/accumulators`}>Back</HoverSelector>
        </div>
      </div>
    </PageLayout>
  );
};

export default Accumulator;
