import React, { FC } from "react";
import { Link } from "react-router-dom";
import useAccumulatorPoolInfo from "./fetchers/pools/useAccumulatorPoolInfo";
import { tokenFormatter } from "./helpers/StringFormatters";
import accumulatorTokenAddresses from "./models/accumulatorTokenAddresses";
import { AccumulatorType } from "./models/accumulatorType";

const BondLink: FC<{ bond: AccumulatorType }> = ({ bond }) => {
  const { data: accumulatorPoolData } = useAccumulatorPoolInfo(bond);
  const { bondMint } = accumulatorTokenAddresses[bond];

  return (
    <div key={bond} className="flex justify-between items-center">
      <div className="w-1/4">wr{bond} Bond</div>
      <div className="w-2/5">
        {accumulatorPoolData
          ? tokenFormatter(
              bondMint,
              accumulatorPoolData.currentPrice.toNumber() / 1000000
            )
          : "Calculating"}
      </div>
      <Link to={`/bonds/wr${bond}`}>
        <button className="med-btn hover:text-white">View</button>
      </Link>
    </div>
  );
};

export default BondLink;
