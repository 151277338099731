import humanizeDuration from "humanize-duration";
import tokensInfo from "../models/tokensInfo";

// Create our number formatter.
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
}).format;

const btcFormatter = (btcAmount: any, decimalPlaces = 4) => {
  btcAmount = parseFloat(btcAmount);
  if (isNaN(btcAmount)) {
    btcAmount = 0;
  }
  return `${btcAmount.toFixed(decimalPlaces)} BTC`;
};

const wrBtcFormatter = (wrBtcAmount: any, decimalPlaces = 4) => {
  wrBtcAmount = parseFloat(wrBtcAmount);
  if (isNaN(wrBtcAmount)) {
    wrBtcAmount = 0;
  }
  return `${wrBtcAmount.toFixed(decimalPlaces)} wrBTC`;
};

const tokenFormatter = (
  tokenAddress: string,
  tokenAmount: number,
  decimalPlaces = 4
) => {
  const { symbol } = tokensInfo[tokenAddress];
  return `${tokenAmount.toFixed(decimalPlaces)} ${symbol}`;
};

const durationFormatter = (durationInMillis: any) => {
  if (durationInMillis <= 0) {
    return "Ready";
  }

  return humanizeDuration(durationInMillis, {
    largest: 3,
    round: true,
    conjunction: " and ",
    serialComma: false,
  });
};

export {
  currencyFormatter,
  btcFormatter,
  wrBtcFormatter,
  tokenFormatter,
  durationFormatter,
};
