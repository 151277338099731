import { AccumulatorType } from "./accumulatorType";

type AccumulatorTokenAddressesType = number | 'bondMint' | 'treasuryMint';

const accumulatorTokenAddresses: {
  [key in AccumulatorType]: {
    [key in AccumulatorTokenAddressesType]: string;
  };
} =
  !process.env["RPC_NETWORK"] || process.env["RPC_NETWORK"] === "mainnet-beta"
    ? {
        [AccumulatorType.BTC]: {
          1: "BTCA1FcBrsmLJLQVNUHwVkmC2PywfK2Qsw3Lb1kFMqGz",
          2: "BTCA2PAfYbqPyeRjMPQdUx6c9yTxQhQL6StdfQjmxmjh",
          3: "BTCA3ZWcWJjW6qcijPSCePZ7WPCgrYm8KJJNVZJzqHd6",
          bondMint: "wrBTCqVjkpqktbqN3CeGVSzQ9PFiPonHN98uwEpwMsy",
          treasuryMint: "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
        },
        [AccumulatorType.ETH]: {
          1: "BTCA1FcBrsmLJLQVNUHwVkmC2PywfK2Qsw3Lb1kFMqGz",
          2: "BTCA2PAfYbqPyeRjMPQdUx6c9yTxQhQL6StdfQjmxmjh",
          3: "BTCA3ZWcWJjW6qcijPSCePZ7WPCgrYm8KJJNVZJzqHd6",
          bondMint: "wrBTCqVjkpqktbqN3CeGVSzQ9PFiPonHN98uwEpwMsy",
          treasuryMint: "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
        },
      }
    : {
        [AccumulatorType.BTC]: {
          1: "C9kzZDDMpvSHPvahcZFH7y6oVhYQKMN2H6E1uVCczdZU",
          2: "3M4QPB369MdYodDkdEnutZhTSJbFUp93szQHQwHYQzD1",
          3: "AMtPJ3N1BAyywN9gvnVszHDmsCthDYysSb3FSEh5PN6V",
          bondMint: "8XKoJZazCL5EVaSK3Vr7dkkS9cMPpdinr2mu1kxJagys",
          treasuryMint: "HQ1zB4ft4RHgvFWAgvgks5vjZRX2i486cfBNnbuzPNxV",
        },
        [AccumulatorType.ETH]: {
          1: "C9kzZDDMpvSHPvahcZFH7y6oVhYQKMN2H6E1uVCczdZU",
          2: "3M4QPB369MdYodDkdEnutZhTSJbFUp93szQHQwHYQzD1",
          3: "AMtPJ3N1BAyywN9gvnVszHDmsCthDYysSb3FSEh5PN6V",
          bondMint: "8XKoJZazCL5EVaSK3Vr7dkkS9cMPpdinr2mu1kxJagys",
          treasuryMint: "HQ1zB4ft4RHgvFWAgvgks5vjZRX2i486cfBNnbuzPNxV",
        },
      };

export default accumulatorTokenAddresses;
