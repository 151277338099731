import React, { FC } from "react";
import { Link } from "react-router-dom";
import useAccumulatorsAPR from "./fetchers/accumulators/useAccumulatorsAPR";
import { AccumulatorType } from "./models/accumulatorType";

type AccumulatorLinkType = {
  title: string;
  accumulatorType: AccumulatorType;
  accumulatorIndex: number;
};

const AccumulatorLink: FC<AccumulatorLinkType> = ({
  title,
  accumulatorType,
  accumulatorIndex,
}) => {
  const {
    data: aprData,
    mutate: refreshAprData,
    isValidating,
  } = useAccumulatorsAPR(accumulatorType);

  if (aprData && Date.now() - aprData.lastFetchedTime > 8.64e7) {
    refreshAprData();
  }

  return (
    <div key={title} className="flex justify-between">
      <div className="flex items-center ">
        <div className="flex items-center w-96 font-pixolletta text-2xl">
          {title}
        </div>
        <div className="flex justify-center items-center w-36">
          {isValidating
            ? "Updating"
            : aprData?.indexData[accumulatorIndex - 1] ?? "Calculating"}
        </div>
      </div>
      <div className="flex items-center justify-end">
        <Link
          to={`/accumulators/${accumulatorType}/${accumulatorIndex}`}
          className="flex items-center justify-center hover:text-white med-btn"
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default AccumulatorLink;
