import React, { FC } from "react";
import PageLayout from "./PageLayout";
import HoverSelector from "./HoverSelector";
import { useParams, useNavigate } from "react-router-dom";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";
import SelectButton from "./SelectButton";
import { btcFormatter, currencyFormatter } from "./helpers/StringFormatters";
import useLiquidityPoolInfo from "./fetchers/pools/useLiquidityPoolInfo";
import useAccumulatorPoolInfo from "./fetchers/pools/useAccumulatorPoolInfo";
import useTokenInfo from "./fetchers/tokens/useTokenInfo";
import { AccumulatorType } from "./models/accumulatorType";

const BondPage: FC = () => {
  const navigate = useNavigate();
  const { bondName } = useParams();

  const { data: wrBtcUsdcPoolData } = useLiquidityPoolInfo(AccumulatorType.BTC);
  const { data: btcaPoolData } = useAccumulatorPoolInfo(AccumulatorType.BTC);
  const { data: btcInfo } = useTokenInfo(AccumulatorType.BTC);

  const wrBtcPriceInUSD = wrBtcUsdcPoolData?.price ?? 0;
  const btcPriceInUSD = btcInfo?.current_price ?? 0;
  const wrBtcBondPriceInBtc =
    (btcaPoolData?.currentPrice.toNumber() ?? 0) / 1000000;
  const roi =
    (wrBtcPriceInUSD / btcPriceInUSD - wrBtcBondPriceInBtc) /
    wrBtcBondPriceInBtc;

  const dialogue = dialogues["bonds"];

  return (
    <PageLayout title={`${bondName} Bond`}>
      <div className="flex flex-col">
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div className="flex flex-col py-4 px-8 bg-gray-backgroundLight items-center justify-center">
          {wrBtcUsdcPoolData && btcInfo && btcaPoolData ? (
            <div className="flex space-x-12 justify-between my-4 text-center ">
              <div className="flex flex-col">
                <div className="text-sm">wrBTC Market Price</div>
                <div>{btcFormatter(wrBtcPriceInUSD / btcPriceInUSD)}</div>
                <div>{currencyFormatter(wrBtcPriceInUSD)}</div>
              </div>
              <div className="flex flex-col">
                <div className="text-sm">Bond Price</div>
                <div>{btcFormatter(wrBtcBondPriceInBtc)}</div>
                <div>
                  {currencyFormatter(wrBtcBondPriceInBtc * btcPriceInUSD)}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-sm">ROI</div>
                <div>{(roi * 100).toFixed(2)}%</div>
              </div>
            </div>
          ) : (
            <div className="flex space-x-12 justify-between my-4 text-center ">
              Please wait...
            </div>
          )}
        </div>

        <div className="my-4 flex-col">
          <SelectButton
            onClick={() => {
              navigate(`/bonds/${bondName}/mint`);
            }}
            text="Mint"
          />
          <SelectButton
            onClick={() => {
              navigate(`/bonds/${bondName}/redeem`);
            }}
            text="Redeem"
          />
        </div>
        <div className="my-2 flex-col">
          <HoverSelector to={`/bonds`}>Back</HoverSelector>
        </div>
      </div>
    </PageLayout>
  );
};

export default BondPage;
