import React, { FC, MouseEventHandler } from "react";
import { ReactComponent as Arrow } from "./assets/arrow.svg";

const SelectButton: FC<{ onClick: MouseEventHandler; text: string }> = ({
  onClick,
  text,
}) => {
  return (
    <button
      className="select-btn hover:font-bold h-12 flex text-lg items-center hover-selector cursor-pointer"
      onClick={onClick}
    >
      <Arrow className={`w-8`} />
      {text}
    </button>
  );
};

export default SelectButton;
