import React, { lazy, Suspense } from "react";
import PageLayout from "./PageLayout";
import divider from "./assets/Other UI/divider_ui.png";
import DashboardAccumulatorInfo from "./DashboardAccumulatorInfo";

const DashboardPlayerInfo = lazy(() => import("./DashboardPlayerInfo"));

export default function Dashboard() {
  return (
    <PageLayout title="Dashboard">
      <div className="flex my-3 text-3xl justify-center">BTCA Information</div>
      <DashboardAccumulatorInfo />

      <img src={divider} alt="" />

      <div className="flex my-6 text-3xl justify-center">Player Portfolio</div>
      <Suspense
        fallback={<div className="justify-center flex"> Loading... </div>}
      >
        <DashboardPlayerInfo />
      </Suspense>
      {/* 
      <div className="flex space-x-8 my-4">
        <div className="mb-8 w-1/2 flex-col items-center justify-center space-y-6">
          <DashboardText title="Bonds" primaryValue={`${dashboardData['Bonds'].priceInBTC.toFixed(4)} BTC`} secondaryValue={`${(dashboardData["Bonds"].apr * 100).toFixed(2)}%`} />
          <DashboardText title="wrBTC-USDC LP" primaryValue={`${formatter.format(dashboardData['wrBTC-USDC LP'].totalValue)}`} secondaryValue={`${(dashboardData["wrBTC-USDC LP"].apr * 100).toFixed(2)}%`} />
        </div>
      </div> */}
    </PageLayout>
  );
}
