import PageLayout from "./PageLayout";
import dialogues from "./models/dialogues";
import { FC, useState } from "react";
import TypeIt from "typeit-react";
import SelectButton from "./SelectButton";

import "./HoverSelector.css";
import Dialogue from "./Dialogue";
import React from "react";

const InfoCenter: FC = () => {
  const [dialogue, setDialogue] = useState(
    dialogues["info_center/select_a_topic"]
  );

  return (
    <PageLayout
      title={dialogue.persona.split(" ")[0]}
      className="justify-between flex flex-col h-full py-8"
    >
      <Dialogue
        persona={dialogues["info_center"].persona}
        message={dialogues["info_center"].message}
      />
      <div className="font-pixolletta text-lg h-64 overflow-y-scroll scrollbar-hide">
        <TypeIt
          options={{
            speed: -15,
            waitUntilVisible: true,
          }}
          key={Math.random()}
        >
          {dialogue.message}
        </TypeIt>
      </div>
      <div className="flex flex-col items-start pb-0.5 bg-gray-backgroundDark">
        <a
          href={"https://learnbtca.player2.world/"}
          target="_blank"
          rel="noreferrer"
          className="w-full"
        >
          <SelectButton onClick={() => {}} text={"Learn First, Ape Second"} />
        </a>

        <SelectButton
          onClick={() => setDialogue(dialogues["info_center/swaps"])}
          text={"Swaps"}
        />

        <SelectButton
          onClick={() => setDialogue(dialogues["info_center/bonds"])}
          text={"Bonds"}
        />

        {/* <SelectButton onClick={() => setDialogue(dialogues["info_center/liquidity_pools"])} text={"Liquidity Pools"} /> */}

        <SelectButton
          onClick={() => setDialogue(dialogues["info_center/accumulators"])}
          text={"Accumulators"}
        />

        <a
          href={"https://leaderboard.player2.world/"}
          target="_blank"
          rel="noreferrer"
          className="w-full"
        >
          <SelectButton onClick={() => {}} text={"DEOs Leaderboard"} />
        </a>

        <a
          href={"https://knowledge.player2.world/"}
          target="_blank"
          rel="noreferrer"
          className="w-full"
        >
          <SelectButton onClick={() => {}} text={"Knowledge Archive"} />
        </a>
      </div>
    </PageLayout>
  );
};

export default InfoCenter;
