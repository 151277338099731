import React from "react";
import PageLayout from "./PageLayout";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";
import { Link } from "react-router-dom";
import divider from "./assets/Other UI/divider_ui.png";
import { currencyFormatter } from "./helpers/StringFormatters";
import useLiquidityPoolInfo from "./fetchers/pools/useLiquidityPoolInfo";
import { AccumulatorType } from "./models/accumulatorType";
import BondLink from "./BondLink";

const defaultBonds = [AccumulatorType.BTC];

export default function BarginHunter() {
  const dialogue = dialogues["bonds"];
  const { data: wrBtcUsdcPoolData } = useLiquidityPoolInfo(AccumulatorType.BTC);

  return (
    <PageLayout title={"Bonds"}>
      <Dialogue persona={dialogue.persona} message={dialogue.message} />
      <div className="flex space-x-8  flex-col">
        <div className=" flex flex-col space-y-4">
          <div className="flex flex-col justify-center items-center ">
            <div className="font-pixolletta font-bold text-2xl mb-2">
              wrBTC Market Price
            </div>
            <div>
              {wrBtcUsdcPoolData
                ? currencyFormatter(wrBtcUsdcPoolData.price)
                : "Calculating"}
            </div>
          </div>

          <img src={divider} alt="" />

          <div className="flex flex-col justify-between p-8 space-y-4 flex-1">
            {defaultBonds.map((bond) => (
              <BondLink bond={bond} />
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
