import React, { FC } from "react";

const DashboardText: FC<{
  title: string;
  dataTip?: string;
  primaryValue: string;
  secondaryValue?: string;
}> = (props) => {
  return (
    <div className="flex items-center py-4 flex-col">
      <div
        data-tip={props.dataTip}
        className="mb-1 text-white font-pixolletta font-bold text-2xl tracking-wider"
      >
        {props.title}
      </div>
      <div className="text-lg">{props.primaryValue}</div>
      {props.secondaryValue ? (
        <div className="text-gray-300 text-lg">{props.secondaryValue}</div>
      ) : (
        []
      )}
    </div>
  );
};

DashboardText.defaultProps = {
  title: "",
  primaryValue: "",
  secondaryValue: undefined,
};

export default DashboardText;
