import React from "react";
import { Link } from "react-router-dom";

const personas = {
  liquidity_pool: "PC-07",
  info_center: "Winterrose the Wise",
  bonds: "Baatar the Bargain Hunter",
  accumulator: "Aljunied the Accumulator",
  trade_center: "Tamarind the Trader",
};

function DialogueMessage(messages: string[]) {
  return messages
    .map((msg, index) => {
      if (index === messages.length - 1) {
        return <span key={Math.random()}>{msg}</span>;
      } else {
        return [
          <span key={Math.random()}>{msg}</span>,
          <br key={Math.random()} />,
        ];
      }
    })
    .flat();
}

const dialogues = {
  dashboard: {
    persona: personas.info_center,
    message: "Isn't our dashboard .... dashing!",
  },
  "dashboard/not_ready": {
    persona: personas.info_center,
    message: DialogueMessage([
      "Oh, a curious one I see. Connect your wallet to find out more.",
    ]),
  },
  info_center: {
    persona: personas.info_center,
    message: [
      <span>If you want to earn, then you should start to learn.</span>,
      <br />,
      <span>
        Skim a summary here or select Knowledge Archive for the full literature.
      </span>,
    ],
  },
  "info_center/welcome": {
    persona: personas.info_center,
    message: DialogueMessage([
      "Welcome to the Financial Brewery; The folks here are constantly brewing up innovative ideas and most are not for the faint of heart. But a journey of a thousand miles begin with a single step. Find me at my study to learn more.",
    ]),
  },
  "info_center/select_a_topic": {
    persona: personas.info_center,
    message: DialogueMessage([
      "Welcome to the Financial Brewery; The folks here are constantly brewing up innovative ideas and most are not for the faint of heart. But a journey of a thousand miles begin with a single step.",
    ]),
  },
  "info_center/swaps": {
    persona: personas.info_center,
    message: [
      <span key={Math.random()}>Looking to acquire a token?</span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        <Link className="underline" to={"/swap"}>
          Tamarind the Trader
        </Link>{" "}
        has them all!
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        She serves some of the most delicious soups too.
      </span>,
    ],
  },
  "info_center/bonds": {
    persona: personas.info_center,
    message: [
      <span key={Math.random()}>A bargain for thee?</span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        Bonds help the protocol grow while giving you an excellent deal.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        Just remember, your tokens will only be fully released after 7 moons.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        Look for{" "}
        <Link className="underline" to={"/bonds"}>
          Baatar the Bargain Hunter
        </Link>{" "}
        should you be interested.
      </span>,
    ],
  },
  "info_center/liquidity_pools": {
    persona: personas.info_center,
    message: [
      <span key={Math.random()}>
        Liquidity pools are what allows trades to happen.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        If you have spare tokens lying around, consider placing them in our
        pools.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        In exchange, you will get a share of the fees that are generated from
        the other Players using the pool.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        Look for{" "}
        <Link className="underline" to={"/liquidity_pools"}>
          PC - 07 the Provider
        </Link>{" "}
        if you are interested.
      </span>,
    ],
  },
  "info_center/accumulators": {
    persona: personas.info_center,
    message: [
      <span key={Math.random()}>
        Ah, an excellent choice for a learned one.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        Accumulators are special tokens that accrues more of its underlying
        asset over time.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        The token captures value when market inefficiencies arise.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        It is an elegant token that requires no work on your part.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        Remember that all it does is accumulate more of the underlying asset for
        you.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        You are still taking on the price risk of the underlying asset.
      </span>,
      <br key={Math.random()} />,
      <span key={Math.random()}>
        Some say accumulators are the secret to{" "}
        <Link className="underline" to={"/accumulators"}>
          Aljunied the Accumulator
        </Link>{" "}
        being so successful.{" "}
      </span>,
    ],
  },

  "info_center/connecting": {
    persona: personas.info_center,
    message: [
      <span key={Math.random()}>
        Discord: our preferred mode of communication:{" "}
      </span>,
      <br key={Math.random()} />,
      <a
        className="underline"
        href="http://discord.gg/player2"
        target="_blank"
        rel="noreferrer"
        key={Math.random()}
      >
        discord.gg/player2
      </a>,
      <div key={Math.random()} className="h-8">
        {" "}
      </div>,
      <span key={Math.random()}>For succinct updates: </span>,
      <br key={Math.random()} />,
      <a
        className="underline"
        href="http://twitter.com/player2world"
        target="_blank"
        rel="noreferrer"
        key={Math.random()}
      >
        twitter.com/player2world
      </a>,
    ],
  },
  liquidity_pools: {
    persona: personas.liquidity_pool,
    message: DialogueMessage([
      "Got some spare tokens lying around? Well put them to use!",
      `We've got quite a menu of token pairings going on....most liquidity pools can get you a pretty sizable return too.`,
    ]),
  },
  liquidity_pool: {
    persona: personas.liquidity_pool,
    message: DialogueMessage([
      `You've got good taste! This one looks promising.`,
    ]),
  },
  "liquidity_pools/not_ready": {
    persona: personas.liquidity_pool,
    message: DialogueMessage([
      "Greetings! The bar is not open yet, come back soon to check out our menu of liquidity pairings.",
    ]),
  },
  "liquidity_pools/pending": {
    persona: personas.liquidity_pool,
    message: DialogueMessage([
      "Please check your wallet to approve the transaction.",
    ]),
  },
  "liquidity_pool/add": {
    persona: personas.liquidity_pool,
    message: DialogueMessage([
      "Deposits must always be added in a 50:50 ratio. No more, no less.",
      "Always perfectly balanced.",
    ]),
  },
  "liquidity_pool/add/success": {
    persona: personas.liquidity_pool,
    message: DialogueMessage([
      "Success!",
      "This calls for a celebration, drinks on the house tonight.",
    ]),
  },
  "liquidity_pool/add/failure": {
    persona: personas.liquidity_pool,
    message: DialogueMessage([
      "Oops, I think I had too much to drink.. please try again!",
    ]),
  },
  "liquidity_pool/remove": {
    persona: personas.liquidity_pool,
    message: DialogueMessage(["Are you sure?"]),
  },
  "liquidity_pool/remove/success": {
    persona: personas.liquidity_pool,
    message: DialogueMessage(["Done! Successfully removed."]),
  },
  "liquidity_pool/remove/failure": {
    persona: personas.liquidity_pool,
    message: DialogueMessage(["Oops, please try again!"]),
  },
  "liquidity_pools/prices_refreshed": {
    persona: personas.liquidity_pool,
    message: "Prices have been refreshed!",
  },
  bonds: {
    persona: personas.bonds,
    message: DialogueMessage([
      "Some say my bonds are the juiciest in Player 2.",
      "Come closer, see for yourself...",
      "Hmmm I can smell you now.",
      "View the bond to see more details.",
      "Come closer...",
    ]),
  },

  "bonds/mint": {
    persona: personas.bonds,
    message: DialogueMessage([
      "Tempted? Should you mint, Baatar will give you wrBTC for your BTC.",
      "Remember that this sacred deal takes 7 moons to complete.",
      "Can you taste the bargain? It's exhilarating, I cannot control my tongue any longer... Grphmmm... *&*&*",
    ]),
  },

  "bonds/mint/pending": {
    persona: personas.bonds,
    message: DialogueMessage([
      "Grphmmm! Check your wallet to approve the transaction.",
      "Or reject if you need time to locate more courage! Baatar will await patiently.",
    ]),
  },
  "bonds/mint/success": {
    persona: personas.bonds,
    message: DialogueMessage([
      "Grphmmm, you are a courageous and smart one. ",
      "Your wrBTC will unlock linearly across 7 moons.",
      "Baatar will never forget your delicious smell.",
    ]),
  },
  "bonds/mint/failure": {
    persona: personas.bonds,
    message: DialogueMessage([
      "Something went wrong with the transaction, are you trying to trick Baatar?",
      "Try again, but do not look down on Baatar.",
      "Come closer, let me touch you...Grphmmm... *&*&*",
    ]),
  },
  "bonds/redeem": {
    persona: personas.bonds,
    message: DialogueMessage([
      "Oh, so you are ready to leave Baatar and collect your dues?",
      "Very well, a deal is a deal.",
      "Now redeem swiftly, before I change my mind and take you to my dungeon...",
    ]),
  },
  "bonds/redeem/success": {
    persona: personas.bonds,
    message: DialogueMessage([
      "It looks like you have successfully collected your dues.",
      "Go now, I know you will come back to Baatar sooner than you think.",
      "In the meantime, I will cherish the memory of your delicious smell forever...Grphmmm... *&*&*.",
    ]),
  },
  "bonds/redeem/failure": {
    persona: personas.bonds,
    message: DialogueMessage([
      "Something went wrong and your dues were not collected.",
      "Are you doubting yourself about leaving Baatar? ",
    ]),
  },
  "bonds/redeem/pending": {
    persona: personas.bonds,
    message: DialogueMessage(["Grphmmm... *&*&* "]),
  },
  "bonds/redeem/no_bonds_to_redeem": {
    persona: personas.bonds,
    message: DialogueMessage([
      "Grphmmm... *&*&* Baatar does not hold the scent of your bonds.",
    ]),
  },
  "bonds/not_ready": {
    persona: personas.bonds,
    message: DialogueMessage([
      "Baatar doesn't smell any wallet connected... Unless you are suggesting an other kind of arrangement with Baatar? Hmmm come closer...",
    ]),
  },
  trader: {
    persona: personas.trade_center,
    message: DialogueMessage([
      "Oh come in, sweet child!",
      "Looking to swap some tokens today, or are you here just to visit Tamarind?",
    ]),
  },
  "trader/not_ready": {
    persona: personas.trade_center,
    message: DialogueMessage([
      "Welcome, my child!",
      "Tamarind's still busy warming up some soups, why don't you come back another time?",
    ]),
  },
  "trader.success": {
    persona: personas.trade_center,
    message: DialogueMessage([
      `Here are your tokens, my child!`,
      `Let me just drop that in your wallet for ya.`,
      `Now why don't you take a look at our soup selection before you go?`,
    ]),
  },
  "trader.fail": {
    persona: personas.trade_center,
    message: DialogueMessage([
      `Oh dear me, something went wrong there!`,
      ` How about I offer you some soup on the house while you try that again?`,
    ]),
  },
  "trader.pending": {
    persona: personas.trade_center,
    message: DialogueMessage(["Confirm the transaction via your wallet. "]),
  },
  accumulators: {
    persona: personas.accumulator,
    message: DialogueMessage([
      "Looking to expand your riches?",
      "Well, you're in luck, these Accumulators are special tokens that continuously grow your underlying assets over time.",
      "One of a kind, I must say....all you have to do is sit back, relax, and let it work its magic.",
    ]),
  },
  "accumulators/info": {
    persona: personas.accumulator,
    message: DialogueMessage(["The APR is based on a 7-day rolling average"]),
  },
  "accumulators/1": {
    persona: personas.accumulator,
    message: DialogueMessage([
      "Oh, the Gold Accumulator...my highly prized collection, with the highest rewards bestowed to those worthy of its perfection. ",
    ]),
  },
  "accumulators/2": {
    persona: personas.accumulator,
    message: DialogueMessage([
      "I see, you've found the Silver Accumulator...another highly sought after treasure, to which I owe the pleasure of the many wonders of my riches.",
    ]),
  },
  "accumulators/3": {
    persona: personas.accumulator,
    message: DialogueMessage([
      "Pssst, this Bronze Accumulator here is a hidden gem. Not many know about it yet, but I have feeling it will grow your riches...I just know it!",
    ]),
  },
  "accumulators/upgrade": {
    persona: personas.accumulator,
    message: DialogueMessage([
      "This is how long it will take should you wish to downgrade your accumulator tokens.",
      "Once you initiate the downgrade, your tokens will cease its accumulating benefit.",
    ]),
  },
  "accumulators/change/success": {
    persona: personas.accumulator,
    message: DialogueMessage(["Voilà, success!"]),
  },
  "accumulators/change/failure": {
    persona: personas.accumulator,
    message: DialogueMessage([
      "Oh no, something went wrong, please try again! ",
    ]),
  },
  "accumulators/change/pending": {
    persona: personas.accumulator,
    message: DialogueMessage(["Confirm the transaction via your wallet. "]),
  },
  "accumulators/downgrade": {
    persona: personas.accumulator,
    message: DialogueMessage([
      "This is how much underlying asset each accumulator token is being backed by.",
      "This is also the multiplier used to upgrade or downgrade your accumulator tokens.",
    ]),
  },
  "accumulators/not_ready": {
    persona: personas.accumulator,
    message: DialogueMessage([
      "If you want to roll with me, you gotta have a wallet connected! Show me the money and I'll show you what I've got! ",
    ]),
  },
};

export default dialogues;
