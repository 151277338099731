import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { Cluster, clusterApiUrl } from "@solana/web3.js";
import React, { FC, ReactNode, useMemo } from "react";

const WalletContext: FC<{ children: ReactNode }> = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network =
    (process.env["RPC_NETWORK"] as WalletAdapterNetwork) ||
    WalletAdapterNetwork.Mainnet;
  // You can also provide a custom RPC endpoint.
  const clusterEndpoint = useMemo(
    () => clusterApiUrl(network as Cluster),
    [network]
  );
  const endpoint =
    !process.env["RPC_NETWORK"] ||
    process.env["RPC_NETWORK"] === WalletAdapterNetwork.Mainnet
      ? "https://solana-mainnet.g.alchemy.com/v2/tf8CMSM0XtEGq79Q_Pni3M1k90_IhMP_"
      : clusterEndpoint;
  // const endpoint = "https://solana-api.projectserum.com";
  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new LedgerWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default WalletContext;
