import useSWR, { Fetcher, Key } from "swr";
import { AccumulatorAdapter } from "player2-solana-sdk";
import { DateTime } from "luxon";
import { durationFormatter } from "../../helpers/StringFormatters";
import useProgram from "../program/useProgram";
import { AccumulatorType } from "../../models/accumulatorType";

export type AccumulatorDowngradeInfoType = {
  pendingBond: number;
  claimableBond: number;
  timeUntilFullyVested: string;
};

type accumulatorDowngradeInfoKeyType = [
  Key,
  AccumulatorType,
  number,
  AccumulatorAdapter
];
export const accumulatorDowngradeInfoKey: Key = "accumulators/info/downgrade";

/* Fetches accumulator downgrade information (how many tokens pending downgrade etc from the chain via SDK. */
const accumulatorDowngradeInfoFetcher: Fetcher<
  AccumulatorDowngradeInfoType,
  accumulatorDowngradeInfoKeyType
> = async (_key, _accumulatorType, index, program) => {
  const downgradeReserve = await program.getDowngradeReserve(index);
  const newDowngradeInfo: AccumulatorDowngradeInfoType = {
    pendingBond: 0,
    claimableBond: 0,
    timeUntilFullyVested: "",
  };

  if (!downgradeReserve) {
    // User did not downgrade any accumulators
    return newDowngradeInfo;
  }

  const { amount, releaseTime } = downgradeReserve;

  if (releaseTime <= DateTime.now().toSeconds()) {
    newDowngradeInfo.pendingBond = newDowngradeInfo.claimableBond =
      parseFloat(amount.toString()) / 1000000;
    newDowngradeInfo.timeUntilFullyVested = "Ready";
  } else {
    newDowngradeInfo.pendingBond = parseFloat(amount.toString()) / 1000000;
    newDowngradeInfo.claimableBond = 0;
    newDowngradeInfo.timeUntilFullyVested = durationFormatter(
      DateTime.fromSeconds(releaseTime).toMillis() - DateTime.now().toMillis()
    );
  }

  return newDowngradeInfo;
};

const useAccumulatorDowngradeInfo = (
  accumulatorType: AccumulatorType,
  index: number
) => {
  const { program } = useProgram(accumulatorType);

  return useSWR(
    program ? [accumulatorDowngradeInfoKey, accumulatorType, index] : null,
    (...args) =>
      accumulatorDowngradeInfoFetcher(...args, program as AccumulatorAdapter),
    {
      dedupingInterval: 60000,
      suspense: true,
    }
  );
};

export default useAccumulatorDowngradeInfo;
