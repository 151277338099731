import PageLayout from "./PageLayout";
import Dialogue from "./Dialogue";
import dialogues from "./models/dialogues";
import ReactTooltip from "react-tooltip";
import info from "./assets/Symbols/info_symbol.png";
import "./Home.css";
import { FC, useEffect } from "react";
import React from "react";
import { AccumulatorType } from "./models/accumulatorType";
import AccumulatorLink from "./AccumulatorLink";

const defaultAccumulators = [
  {
    title: "Gold BTC Accumulator",
    accumulatorType: AccumulatorType.BTC,
    accumulatorIndex: 1,
  },
  {
    title: "Silver BTC Accumulator",
    accumulatorType: AccumulatorType.BTC,
    accumulatorIndex: 2,
  },
  {
    title: "Bronze BTC Accumulator",
    accumulatorType: AccumulatorType.BTC,
    accumulatorIndex: 3,
  },
];

const Accumulators: FC = () => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  return (
    <PageLayout title={"Accumulator"}>
      <div className="flex flex-col">
        <Dialogue
          persona={dialogues["accumulators"].persona}
          message={dialogues["accumulators"].message}
        />

        <div className={"flex-col justify-center items-center space-y-4 "}>
          <div className="flex w-full">
            <div className=" text-center flex flex-col space-y-4 w-96 text-white ">
              <div>Name</div>
            </div>
            <div
              data-tip={"Estimated APR based on a 90-day rolling average"}
              className=" flex items-center space-x-2 justify-center text-white w-36"
            >
              <div>APR</div>
              <img src={info} className="h-6" alt="refresh" />
            </div>
          </div>

          <div className="flex flex-col  space-y-4">
            {defaultAccumulators.map((accumulator) => (
              <AccumulatorLink key={accumulator.title} {...accumulator} />
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Accumulators;
