import BN from "bn.js";
import { AccumulatorAdapter } from "player2-solana-sdk";
import useSWR, { Fetcher, Key } from "swr";
import { AccumulatorType } from "../../models/accumulatorType";
import useProgram from "../program/useProgram";

type AccumulatorPoolInfoType = {
  initPrice: BN;
  minPrice: BN;
  decAmount: BN;
  decTick: number;
  currentPrice: BN;
};

type AccumulatorPoolInfoKeyType = [Key, AccumulatorType, AccumulatorAdapter];

const accumulatorPoolInfoKey: Key = "pools/info";

/* Fetches btcaPool information from chain */
const accumulatorPoolInfoFetcher: Fetcher<
  AccumulatorPoolInfoType,
  AccumulatorPoolInfoKeyType
> = async (_key, _accumulatorType, program) => await program.getFormattedPool();

const useAccumulatorPoolInfo = (accumulatorType: AccumulatorType) => {
  const { program } = useProgram(accumulatorType);

  return useSWR(
    program ? [accumulatorPoolInfoKey, accumulatorType] : null,
    (accumulatorPoolInfoKey, accumulatorType) =>
      accumulatorPoolInfoFetcher(
        accumulatorPoolInfoKey,
        accumulatorType,
        program as AccumulatorAdapter
      ),
    {
      dedupingInterval: 5000,
    }
  );
};

export default useAccumulatorPoolInfo;
