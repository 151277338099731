import { Fetcher, Key, mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import { AccumulatorType } from "../../models/accumulatorType";

type AccumulatorsAPR = {
  lastFetchedTime: number;
  indexData: Array<string>;
};
type AccumulatorsAPRKeyType = [Key, AccumulatorType];

const accumulatorsAPRKey: Key = "accumulators/apr";
const accumulatorsAPRUrl: { [key in AccumulatorType]: string } = {
  [AccumulatorType.BTC]: `https://us-central1-genuine-citron-346723.cloudfunctions.net/getLatestIndex`,
  // TODO: Change ETH APR Url
  [AccumulatorType.ETH]: `https://us-central1-genuine-citron-346723.cloudfunctions.net/getLatestIndex`,
};
// For SSR Protection; to ensure code runs in browser
if (typeof window !== "undefined") {
  const data = localStorage.getItem(accumulatorsAPRKey);
  if (data) mutate(accumulatorsAPRKey, JSON.parse(data), false);
}

/* Fetches Accumulator APR from Google Cloud. Cloud Functions calculate the APR on a rolling basis */
const accumulatorsAPRFetcher: Fetcher<
  AccumulatorsAPR,
  AccumulatorsAPRKeyType
> = (_key, accumulatorType) =>
  fetch(accumulatorsAPRUrl[accumulatorType], {
    method: "GET",
    headers: {
      "Access-Control-Allow-Methods": "GET",
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => ({
      lastFetchedTime: Date.now(),
      indexData: data[0],
    }));
  // {
  //   return {
  //     lastFetchedTime: Date.now(),
  //     indexData: ["1.19%", "0.79%", "0.39%"],
  //   };
  // };

const useAccumulatorsAPR = (accumulatorType: AccumulatorType) =>
  useSWRImmutable(
    [accumulatorsAPRKey, accumulatorType],
    accumulatorsAPRFetcher,
    {
      onSuccess: (data) => {
        localStorage.setItem(accumulatorsAPRKey, JSON.stringify(data));
      },
      onError: () => {
        localStorage.removeItem(accumulatorsAPRKey);
      },
    }
  );

export default useAccumulatorsAPR;
