import { FC, useCallback, useEffect, useRef, useState } from "react";

import { RouteInfo, TOKEN_LIST_URL, TransactionFeeInfo } from "@jup-ag/core";
import { useJupiter } from "@jup-ag/react-hook";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";

import next from "./assets/next.png";
import refreshImg from "./assets/Symbols/swap_blk_symbol.png";
import refreshHover from "./assets/Symbols/swap_wht_symbol.png";
import Dialogue from "./Dialogue";
import { inputValidator } from "./helpers/InputValidator";
import Loading from "./Loading";
import addresses from "./models/btcaAddresses";
import dialogues from "./models/dialogues";
import PageLayout from "./PageLayout";
import React from "react";
import useTokenBalances from "./fetchers/tokens/useTokenBalances";

const defaultOptions = [
  { token: "USDC", address: new PublicKey(addresses.usdcMint), decimal: 6 },
  {
    token: "wrBTC",
    address: new PublicKey(addresses.rewardMint),
    decimal: 6,
  },
  {
    token: "BTC",
    address: new PublicKey(addresses.treasuryMint),
    decimal: 6,
  },
  {
    token: "mSOL",
    address: new PublicKey(addresses.mSolMint),
    decimal: 6,
  },
  {
    token: "UST",
    address: new PublicKey(addresses.ustMint),
    decimal: 6,
  },
  {
    token: "UXD",
    address: new PublicKey(addresses.uxdMint),
    decimal: 6,
  },
  {
    token: "SUNNY",
    address: new PublicKey(addresses.sunnyMint),
    decimal: 6,
  },
  {
    token: "SBR",
    address: new PublicKey(addresses.saberMint),
    decimal: 6,
  },
  {
    token: "ETH",
    address: new PublicKey(addresses.ethMint),
    decimal: 8,
  },
  {
    token: "SOL",
    address: new PublicKey(addresses.solMint),
    decimal: 9,
  },
];

type defaultOptionsType = {
  token: string;
  address: PublicKey;
  decimal: number;
};

type defaultTokenBalancesType = {
  USDC: number;
  wrBTC: number;
  BTC: number;
  mSOL: number;
  UST: number;
  UXD: number;
  SUNNY: number;
  SBR: number;
  ETH: number;
  SOL: number;
};

const defaultTokenBalances = {
  USDC: 0,
  wrBTC: 0,
  BTC: 0,
  mSOL: 0,
  UST: 0,
  UXD: 0,
  SUNNY: 0,
  SBR: 0,
  ETH: 0,
  SOL: 0,
};

const getRouteName = (route: RouteInfo) => {
  let routeName = "";
  route.marketInfos.forEach((marketInfo, index) => {
    if (index === 0) {
      routeName += `${marketInfo.amm.label}`;
    } else {
      routeName += ` x ${marketInfo.amm.label}`;
    }
  });
  return routeName;
};

const Swap: FC = () => {
  const [tokens, setTokens] = useState<any[]>([]);
  const [options, setOptions] = useState(defaultOptions);
  const [optionsFor, setOptionsFor] = useState(defaultOptions);
  const [showTradeOptions, setShowTradeOptions] = useState(false);
  const [showForOptions, setShowForOptions] = useState(false);
  const [tradeOption, setTradeOption] = useState(options[0]);
  const [tradeForOption, setTradeForOption] = useState(options[1]);
  const [tokenBalances, setTokenBalances] =
    useState<defaultTokenBalancesType>(defaultTokenBalances);
  const [availableToken, setAvailableToken] = useState(0);
  const [availableForToken, setAvailableForToken] = useState(0);
  const [swapAmount, setSwapAmount] = useState(0);
  const [jupiterParam, setJupiterParam] = useState({
    amount: 0,
    inputMint: defaultOptions[0].address,
    outputMint: defaultOptions[1].address,
    slippage: 1,
    debounceTime: 250,
  });
  const [buttonText, setButtonText] = useState("Swap");
  const [dialogue, setDialogue] = useState(dialogues["trader"]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [routePreference, setRoutePreference] = useState(0);
  const [depositAndFee, setDepositAndFee] = useState<TransactionFeeInfo>();

  const { data: tokenBalancesData, mutate: refreshTokenBalances } =
    useTokenBalances();

  useEffect(() => {
    if (tokenBalancesData) {
      const newTokenBalances: defaultTokenBalancesType = {
        USDC: 0,
        wrBTC: 0,
        BTC: 0,
        mSOL: 0,
        UST: 0,
        UXD: 0,
        SUNNY: 0,
        SBR: 0,
        ETH: 0,
        SOL: 0,
      };
      newTokenBalances.BTC =
        tokenBalancesData.formattedInfos[addresses.treasuryMint].uiAmount;
      newTokenBalances.wrBTC =
        tokenBalancesData.formattedInfos[addresses.rewardMint].uiAmount;
      newTokenBalances.USDC =
        tokenBalancesData.formattedInfos[addresses.usdcMint].uiAmount;
      newTokenBalances.mSOL =
        tokenBalancesData.formattedInfos[addresses.mSolMint].uiAmount;
      newTokenBalances.UST =
        tokenBalancesData.formattedInfos[addresses.ustMint].uiAmount;
      newTokenBalances.UXD =
        tokenBalancesData.formattedInfos[addresses.uxdMint].uiAmount;
      newTokenBalances.SUNNY =
        tokenBalancesData.formattedInfos[addresses.sunnyMint].uiAmount;
      newTokenBalances.SBR =
        tokenBalancesData.formattedInfos[addresses.saberMint].uiAmount;
      newTokenBalances.ETH =
        tokenBalancesData.formattedInfos[addresses.ethMint].uiAmount;
      newTokenBalances.SOL =
        tokenBalancesData.formattedInfos[addresses.solMint].uiAmount;

      const sortedOptions = Object.entries(newTokenBalances)
        .sort((a, b) => b[1] - a[1])
        .reduce<defaultOptionsType[]>((_sortedDefaults, [k, _v]) => {
          const option = options.find((o) => o.token === k);
          if (option) {
            return _sortedDefaults.concat(option);
          } else {
            return _sortedDefaults;
          }
        }, []);

      setOptions(sortedOptions);
      setOptionsFor(sortedOptions);
      setAvailableToken(newTokenBalances.USDC);
      setAvailableForToken(newTokenBalances.wrBTC);
      setTokenBalances((prevState) => ({
        ...prevState,
        ...newTokenBalances,
      }));
    }
  }, [tokenBalancesData]);

  const wallet = useWallet();
  const { connection } = useConnection();
  const refreshEl = useRef<HTMLImageElement>(null);

  const jupiter = useJupiter(jupiterParam);
  const {
    exchange, // exchange
    loading, // loading states
    routes, // all the routes from inputMint to outputMint
    allTokenMints,
  } = jupiter;

  useEffect(() => {
    // Fetch token list from Jupiter API
    fetch(TOKEN_LIST_URL["mainnet-beta"])
      .then((response) => response.json())
      .then((result) => {
        const tokens = allTokenMints.map((mint) =>
          result.find((item: any) => item?.address === mint)
        );
        setTokens(tokens);
      });
  }, [allTokenMints]);

  useEffect(() => {
    const getDepositAndFee = async () => {
      if (!routes) {
        return;
      }
      const fees = await routes[routePreference].getDepositAndFee();
      if (fees) {
        setDepositAndFee(fees);
      }
    };

    if (routes && routes[routePreference] && wallet.connected) {
      getDepositAndFee();
    }
  }, [routePreference, routes, wallet.connected]);

  useEffect(() => {
    if (initialLoad && !loading) {
      setInitialLoad(false);
    }
  }, [initialLoad, loading]);

  function selectTradeOption(option: defaultOptionsType) {
    setShowTradeOptions(!showTradeOptions);
    setTradeOption(option);
    setJupiterParam((prevState) => ({
      ...prevState,
      inputMint: option.address,
    }));
    setSwapAmount(0);
    setRoutePreference(0);
  }

  function selectForOption(option: defaultOptionsType) {
    setShowForOptions(!showForOptions);
    setTradeForOption(option);
    setRoutePreference(0);
    setJupiterParam((prevState) => ({
      ...prevState,
      outputMint: option.address,
    }));
  }

  function swapForAndTo() {
    setTradeForOption(tradeOption);
    setTradeOption(tradeForOption);
    setAvailableToken(availableForToken);
    setAvailableForToken(availableToken);
    setSwapAmount(0);
    setRoutePreference(0);
    setJupiterParam((prevState) => ({
      ...prevState,
      amount: 0,
      inputMint: tradeForOption.address,
      outputMint: tradeOption.address,
    }));

    refreshEl.current?.classList.add("animate-spin");
    setTimeout(() => {
      refreshEl.current?.classList.remove("animate-spin");
    }, 500);
  }

  // to set when new token is selected
  const [searchInput, setSearchInput] = useState("");
  const [searchForInput, setSearchForInput] = useState("");

  const search = (value: string) => {
    setSearchInput(value);
    setOptions(
      defaultOptions.filter(
        (opt) => opt.token.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
    );
  };

  const searchOptionsFor = (value: string) => {
    setSearchForInput(value);
    setOptionsFor(
      defaultOptions.filter(
        (opt) => opt.token.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
    );
  };

  const swap = useCallback(async () => {
    if (
      !wallet ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction ||
      !routes ||
      !connection ||
      !tokenBalances
    ) {
      return;
    }

    setButtonText("Pending");
    setDialogue(dialogues["trader.pending"]);
    exchange({
      wallet: {
        sendTransaction: wallet.sendTransaction,
        publicKey: wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction,
      },
      routeInfo: routes[routePreference],
      onTransaction: async (txid) => {
        await connection.confirmTransaction(txid);

        return await connection.getTransaction(txid, {
          commitment: "confirmed",
        });
      },
    })
      .then((response) => {
        if ("error" in response) {
          setButtonText("Error");
          setDialogue(dialogues["trader.fail"]);
        } else if (
          "inputAmount" in response &&
          "outputAmount" in response &&
          response.inputAmount &&
          response.outputAmount
        ) {
          setButtonText("Success");
          setSwapAmount(0);
          setDialogue(dialogues["trader.success"]);

          const afterSwapValues = {
            [tradeOption.token]:
              (tokenBalances as any)[tradeOption.token] -
              response.inputAmount / Math.pow(10, tradeForOption.decimal),
            [tradeForOption.token]:
              (tokenBalances as any)[tradeForOption.token] +
              response.outputAmount / Math.pow(10, tradeForOption.decimal),
          };
          setAvailableToken(afterSwapValues[tradeOption.token]);
          setAvailableForToken(afterSwapValues[tradeForOption.token]);
          setTokenBalances((prevState) => ({
            ...prevState,
            ...afterSwapValues,
          }));
        }
      })
      .catch((err) => {
        setButtonText("Error");
        setDialogue(dialogues["trader.fail"]);
      });
  }, [
    wallet,
    routes,
    connection,
    tokenBalances,
    exchange,
    routePreference,
    tradeOption.token,
    tradeForOption.decimal,
    tradeForOption.token,
  ]);

  if (tokenBalances.wrBTC < 0 || tokenBalances.USDC < 0 || initialLoad) {
    return <Loading title="Swap" />;
  }

  return (
    <PageLayout title={"Swap"}>
      <div className="flex flex-col">
        <Dialogue persona={dialogue.persona} message={dialogue.message} />
        <div className="flex-col ">
          <div className="flex justify-between text-sm">
            <div className="table-header text-left">Trade</div>
            <div>{`Available ${availableToken}`}</div>
          </div>

          <div className="flex justify-between mb-4 mt-1 items-center">
            <div className="flex flex-col">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => setShowTradeOptions(!showTradeOptions)}
              >
                {tradeOption.token}
                <img
                  src={next}
                  alt="next"
                  className={`h-4 ml-2 ${showTradeOptions ? "rotate-90" : ""}`}
                />
              </div>
            </div>

            <label
              htmlFor="tradeInput"
              className="flex items-center justify-center relative"
            >
              <input
                id="tradeInput"
                type="number"
                className="flex rounded-lg w-96 h-14 focus:outline-0 p-2 flex-1 "
                placeholder="Amount"
                value={swapAmount}
                onChange={(e) => {
                  const amount = inputValidator(
                    e.target.valueAsNumber,
                    availableToken,
                    6
                  );

                  setSwapAmount(amount);
                  setJupiterParam((prevState) => ({
                    ...prevState,
                    amount: amount * Math.pow(10, tradeOption.decimal),
                  }));
                }}
              />
              <button
                onClick={() => {
                  const amount = inputValidator(
                    availableToken / 2,
                    availableToken / 2,
                    6
                  );

                  setSwapAmount(amount);
                  setJupiterParam((prevState) => ({
                    ...prevState,
                    amount: amount * Math.pow(10, tradeOption.decimal),
                  }));
                }}
                className="absolute translate-x-[-110%] right-0 z-1 sm-btn text-sm w-14 hover:text-white"
              >
                Half
              </button>
              <button
                onClick={() => {
                  const amount = inputValidator(
                    availableToken,
                    availableToken,
                    6
                  );

                  setSwapAmount(amount);
                  setJupiterParam((prevState) => ({
                    ...prevState,
                    amount: amount * Math.pow(10, tradeOption.decimal),
                  }));
                }}
                className="absolute right-1 z-1 sm-btn text-sm w-14 hover:text-white"
              >
                Max
              </button>
            </label>
          </div>

          {showTradeOptions ? (
            <div className="flex flex-col w-1/2">
              <input
                placeholder="Search"
                className="flex rounded-lg w-96 h-14 focus:outline-0 p-2 flex-1 mb-4"
                value={searchInput}
                onChange={(e) => search(e.target.value)}
              />
              <div className="h-24 overflow-y-scroll flex flex-col border-2 p-2 rounded-md">
                {options.map((o) => (
                  <div
                    key={o.token}
                    className="cursor-pointer flex justify-between"
                    onClick={(e) => {
                      const { innerText } = e.target as HTMLInputElement;
                      const option = defaultOptions.find(
                        (o) => o.token === innerText
                      );

                      if (option) {
                        selectTradeOption(option);
                      }

                      setAvailableToken((tokenBalances as any)[o.token]);
                    }}
                  >
                    <div className="flex-1">
                      <div>{o.token}</div>
                    </div>
                    <div className="pointer-events-none">
                      {(tokenBalances as any)[o.token]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            []
          )}

          <div className="flex justify-center my-4">
            <button onClick={swapForAndTo} className="">
              <img
                alt="refresh"
                ref={refreshEl}
                src={refreshImg}
                className="h-6"
                onMouseOut={(e) =>
                  ((e.target as HTMLInputElement).src = refreshImg)
                }
                onMouseOver={(e) =>
                  ((e.target as HTMLInputElement).src = refreshHover)
                }
              />
            </button>
          </div>

          <div className="flex justify-between text-sm items-center">
            <div className="table-header text-left">For(estimated)</div>
            <div>{`Available ${availableForToken}`}</div>
          </div>

          <div className="flex justify-between mb-4 mt-1 space-x-48 items-center">
            <button
              className="flex items-center"
              onClick={() => setShowForOptions(!showForOptions)}
            >
              <div>{tradeForOption.token}</div>
              <img
                src={next}
                alt="next"
                className={`h-4 ml-2 ${showForOptions ? "rotate-90" : ""}`}
              />
            </button>
            <label
              htmlFor="forInput"
              className="flex items-center justify-center relative"
            >
              <input
                id="forInput"
                min="0"
                className="flex rounded-lg w-96 h-14 focus:outline-0 p-2 flex-1 "
                placeholder="Calculating"
                value={
                  swapAmount &&
                  swapAmount > 0 &&
                  routes &&
                  routes[routePreference] &&
                  !loading
                    ? routes[routePreference].outAmount /
                      Math.pow(10, tradeForOption.decimal)
                    : "Calculating"
                }
                readOnly
              />
            </label>
          </div>

          {showForOptions ? (
            <div className="flex flex-col w-1/2">
              <input
                placeholder="Search"
                className="flex rounded-lg w-96 h-14 focus:outline-0 p-2 flex-1 mb-4"
                value={searchForInput}
                onChange={(e) => searchOptionsFor(e.target.value)}
              />
              <div className="h-24 overflow-y-scroll flex flex-col border-2 p-2 rounded-md">
                {optionsFor.map((o) => (
                  <div
                    key={o.token}
                    className="cursor-pointer flex justify-between"
                    onClick={(e) => {
                      const { innerText } = e.target as HTMLInputElement;
                      const option = defaultOptions.find(
                        (o) => o.token === innerText
                      );

                      if (option) {
                        selectForOption(option);
                      }

                      setAvailableForToken((tokenBalances as any)[o.token]);
                    }}
                  >
                    <div className="flex-1">
                      <div>{o.token}</div>
                    </div>
                    <div className="pointer-events-none">
                      {(tokenBalances as any)[o.token]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            []
          )}
        </div>

        <div className="flex-col my-4">
          <div className="flex justify-between">
            <div className="flex">Rate</div>
            <div className="flex">
              {!loading && routes && routes[routePreference] && swapAmount > 0
                ? `1 ${tradeForOption.token} = ${(
                    routes[routePreference].inAmount /
                    routes[routePreference].outAmount
                  ).toFixed(6)} ${tradeOption.token}`
                : `Calculating`}
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex">Price Impact</div>
            <div className="flex">
              {!loading && routes && routes[routePreference] && swapAmount > 0
                ? `${(routes[routePreference].priceImpactPct * 100).toFixed(
                    2
                  )}%`
                : `Calculating`}
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex my-4">Route Preference</div>
            {!loading && routes ? (
              <select
                className="h-12 p-2 my-4 rounded-md"
                onClick={(e) => {
                  if ((e.target as HTMLInputElement).value) {
                    setRoutePreference(
                      parseInt((e.target as HTMLInputElement).value)
                    );
                  }
                }}
              >
                {(routes || []).map((route, index) => {
                  if (
                    route.inAmount !== 0 &&
                    route.outAmount !== 0 &&
                    route.priceImpactPct < 0.5
                  ) {
                    return (
                      <option className="h-12 my-4" value={index} key={index}>
                        {getRouteName(route)}
                      </option>
                    );
                  }
                })}
              </select>
            ) : (
              <div className="my-4">Searching via Jupiter</div>
            )}
          </div>

          <div className="flex justify-between items-center">
            <div className="flex">Swap Route</div>
            {!loading && routes && routes[routePreference] ? (
              <div>
                {tradeOption.token} {"-> "}
                {routes[routePreference].marketInfos.map((r, index) => {
                  const showArrow =
                    index !== routes[routePreference].marketInfos.length - 1
                      ? true
                      : false;
                  return (
                    <>
                      {
                        tokens.find(
                          (item) => item?.address === r?.outputMint?.toString()
                        )?.symbol
                      }
                      {showArrow ? " -> " : ""}
                    </>
                  );
                })}
              </div>
            ) : (
              "Calculating"
            )}
          </div>

          <div className="flex justify-between items-center">
            <div className="flex">Swap Fee</div>
            {!loading && routes && routes[routePreference] && depositAndFee
              ? `~${depositAndFee.totalFeeAndDeposits / 1000000000} SOL`
              : "Calculating"}
          </div>

          <div className="flex justify-right my-4">
            <button
              onClick={() => {
                refreshTokenBalances();
              }}
            >
              Refresh Balances
            </button>
          </div>

          <div className="flex pt-6 justify-center">
            <button
              className="long-btn hover:text-white"
              onClick={swap}
              disabled={swapAmount <= 0}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Swap;
