import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import homeBg from "./assets/Home-bg.png";
import homeBgAnimation from "./assets/Home-bg-animation.gif";
import dashboardUI from "./assets/Dashboard_UI_btn.png";
import dashboardUIHover from "./assets/Dashboard_UI_Hover.png";
import { useState, useEffect } from "react";

type PageLayoutProps = {
  children: ReactNode;
  className?: string;
  title: string;
};

const PageLayout: FC<PageLayoutProps> = ({ children, className, title }) => {
  const [backgroundHeight, setbackgroundHeight] = useState(
    Math.min(
      window.innerWidth > 800
        ? window.innerWidth * 0.44
        : window.innerWidth * 0.8,
      window.innerHeight * 0.8
    )
  );
  const backgroundHeightWidthRatio = 974 / 994;
  const backgroundWidth = Math.min(
    Math.round(window.innerHeight * 0.8 * backgroundHeightWidthRatio),
    window.innerWidth > 800 ? window.innerWidth * 0.44 : window.innerWidth * 0.9
  );
  useEffect(() => {
    function handleResize() {
      setbackgroundHeight(Math.round(window.innerHeight * 0.8));
    }
    window.addEventListener("resize", handleResize);
  });

  return (
    <div className="flex justify-center items-center min-h-screen relative  pt-20 pb-32">
      <img
        alt=""
        src={homeBg}
        className="absolute z-0 pointer-events-none"
        style={{
          height: `${backgroundHeight}px`,
          width: `${backgroundWidth}px`,
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
        }}
      />
      <img
        alt=""
        src={homeBgAnimation}
        className="absolute z-0 pointer-events-none"
        style={{
          height: `${backgroundHeight}px`,
          width: `${backgroundWidth}px`,
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
        }}
      />

      <Link
        to=""
        className="w-fit-content absolute z-20"
        style={{
          height: `${backgroundHeight * 0.14}px`,
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -${backgroundHeight * 0.55}px)`,
        }}
      >
        <img
          alt=""
          src={dashboardUI}
          className="cursor-pointer"
          style={{ height: "100%", width: "auto" }}
          // onMouseOver={e => e.target.src = dashboardUIHover}
          // onMouseOut={(e) => (e.target.src = dashboardUI)}
        />
      </Link>

      <div className="rounded-2xl px-12 py-8 relative container z-20 mb-24">
        <div
          className="container-header w-fit-content select-none flex justify-center items-center absolute top-0 z-10 text-4xl font-medium font-pixolletta tracking-wide uppercase"
          style={{ transform: "translateY(-30%)" }}
        >
          {title}
        </div>
        <Link to="/">
          <button
            className="absolute text-md px-4 py-2 flex items-center justify-center esc-btn uppercase text-white"
            style={{ top: "-2%", right: "-1%" }}
          >
            esc
          </button>
        </Link>
        <div className={"mt-4 relative pt-20 " + className}>{children}</div>
      </div>
    </div>
  );
};

export default PageLayout;
