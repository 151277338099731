import { jsonInfo2PoolKeys } from "@raydium-io/raydium-sdk";
import useSWR, { Fetcher, Key } from "swr";
import { AccumulatorType } from "../../models/accumulatorType";

export type LiquidityPoolInfoType = {
  name: string;
  pair_id: string;
  lp_mint: string;
  official: boolean;
  liquidity: number;
  market: string;
  volume_24h: number;
  volume_24h_quote: number;
  fee_24h: number;
  fee_24h_quote: number;
  volume_7d: number;
  volume_7d_quote: number;
  fee_7d: number;
  fee_7d_quote: number;
  price: number;
  lp_price: number;
  amm_id: string;
  token_amount_coin: number;
  token_amount_pc: number;
  token_amount_lp: number;
  apy: number;
};

export const wrBtcUsdcPoolKeys = jsonInfo2PoolKeys({
  id: "9revsUtaD9ttP48KMmRx89G7HaHNt5UdM9KTF1D3pbui",
  baseMint: "wrBTCqVjkpqktbqN3CeGVSzQ9PFiPonHN98uwEpwMsy",
  quoteMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  lpMint: "3d1oXrdZATm2vcZ44tUtBmWcAXPziWmZfxbsVt9smHkC",
  baseDecimals: 6,
  quoteDecimals: 6,
  lpDecimals: 6,
  version: 4,
  programId: "675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8",
  authority: "5Q544fKrFoe6tsEbD7S8EmxGTJYAKtTVhAW5Q5pge4j1",
  openOrders: "4o9Fde3mfvkTyiZUUSMNn1bv9PZEsJEN2E8yZ2HSz7FQ",
  targetOrders: "63AjcV27Mt5jdsdA7QCRwgdpRRBHvjBL4YQpiVnfm3kh",
  baseVault: "ByNxUggAuvEq5rwbUWB1pBo4v8oVZC4nGFHLaMxrh8zM",
  quoteVault: "51jD1YhKMmQ33JSJw6NM1ssRim9sectdGzfCrZEZgcki",
  withdrawQueue: "EJFsGDnUXq8JboCZpwKqPyCx4oZdpaCF6Z7TyQ84SX6o",
  lpVault: "6MaYoCYqQ3TBkDCR897dG4dua3JbrcKs3QtqV6kzkh5P",
  marketVersion: 3,
  marketProgramId: "9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin",
  marketId: "HwdKmPBUE1xigJKqyhLG92zWYkXFWR7T3eRHnxpVqvpQ",
  marketAuthority: "J1MwZtoV3cDdRHXPvyanP4bv5cqV7KpL85xmSzYFBd2f",
  marketBaseVault: "DFzAZXgDkAaNhoKCyU2sf3TBoKHiUkkBrEqih7SBKEUj",
  marketQuoteVault: "8dbAxqD9uGzwSUTLFDrDxsRU23SyS4ndyUvkrr8w5GHg",
  marketBids: "2QT177KkjEDgfjU5UYMswFyUynVLazYyENvMxATofidu",
  marketAsks: "9JiAnZWh6SzpQh5HjrcZx37eZAeKkv8mxJhfAxw5au4k",
  marketEventQueue: "DMnuFHnzPHgirBJGLu98VQzLo8Wegry57ZNoVtJASgag",
});

const liquidityPoolInfoKey: Key = "pools/wrbtc-usdc/info";
type LiquidityPoolInfoKeyType = [Key, AccumulatorType];

const liquidityPoolInfoAmmId: { [key in AccumulatorType]: string } = {
  [AccumulatorType.BTC]: "9revsUtaD9ttP48KMmRx89G7HaHNt5UdM9KTF1D3pbui",
  // TODO: Change ETH AmmId
  [AccumulatorType.ETH]: "9revsUtaD9ttP48KMmRx89G7HaHNt5UdM9KTF1D3pbui",
};

/* Fetches liquidityPool information from Raydium's API */
const liquidityPoolInfoFetcher: Fetcher<
  LiquidityPoolInfoType,
  LiquidityPoolInfoKeyType
> = async (_key, accumulatorType) =>
  fetch(
    `https://api.raydium.io/pairs?amm_id=${liquidityPoolInfoAmmId[accumulatorType]}`
  )
    .then((response) => response.json())
    .then(([data]) => data);

const useLiquidityPoolInfo = (accumulatorType: AccumulatorType) =>
  useSWR([liquidityPoolInfoKey, accumulatorType], liquidityPoolInfoFetcher);

export default useLiquidityPoolInfo;
