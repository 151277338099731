import React, { FC } from "react";
import DashboardText from "./DashboardText";
import { useBtcaTreasuryInfo } from "./fetchers/accounts/useTreasuryInfo";
import useAccumulatorInfo from "./fetchers/accumulators/useAccumulatorInfo";
import useLiquidityPoolInfo from "./fetchers/pools/useLiquidityPoolInfo";
import useTokenInfo from "./fetchers/tokens/useTokenInfo";
import { btcFormatter, currencyFormatter } from "./helpers/StringFormatters";
import { AccumulatorType } from "./models/accumulatorType";

const DashboardAccumulatorInfo: FC = () => {
  const { data: btca1Data } = useAccumulatorInfo(AccumulatorType.BTC, 1);
  const { data: btca2Data } = useAccumulatorInfo(AccumulatorType.BTC, 2);
  const { data: btca3Data } = useAccumulatorInfo(AccumulatorType.BTC, 3);
  const { data: wrBtcUsdcPoolData } =useLiquidityPoolInfo(AccumulatorType.BTC);
  const { data: btcData } = useTokenInfo(AccumulatorType.BTC);
  const { data: btcaTreasuryData } = useBtcaTreasuryInfo();
  if (
    !btcData ||
    !btca1Data ||
    !btca2Data ||
    !btca3Data ||
    !btcaTreasuryData ||
    !wrBtcUsdcPoolData
  ) {
    return <div className="justify-center flex my-6"> Loading... </div>;
  }

  const wrBtcCurrentPrice = wrBtcUsdcPoolData.price;
  const btcCurrentPrice = btcData.current_price;
  const treasuryValue = btcaTreasuryData.tokenInfo.tokenAmount.uiAmount;

  return (
    <>
      <div className="justify-around flex">
        <DashboardText
          title="BTCA1"
          primaryValue={btcFormatter(
            (btca1Data.index * wrBtcCurrentPrice) / btcCurrentPrice
          )}
          secondaryValue={currencyFormatter(
            btca1Data.index * wrBtcCurrentPrice
          )}
        />
        <DashboardText
          title="BTCA2"
          primaryValue={btcFormatter(
            (btca2Data.index * wrBtcCurrentPrice) / btcCurrentPrice
          )}
          secondaryValue={currencyFormatter(
            btca2Data.index * wrBtcCurrentPrice
          )}
        />
        <DashboardText
          title="BTCA3"
          primaryValue={btcFormatter(
            (btca3Data.index * wrBtcCurrentPrice) / btcCurrentPrice
          )}
          secondaryValue={currencyFormatter(
            btca3Data.index * wrBtcCurrentPrice
          )}
        />
      </div>
      <div className="justify-around flex">
        <DashboardText
          title="wrBTC Price"
          primaryValue={btcFormatter(wrBtcCurrentPrice / btcCurrentPrice)}
          secondaryValue={currencyFormatter(wrBtcCurrentPrice)}
        />
        <DashboardText
          title="Treasury"
          primaryValue={btcFormatter(treasuryValue)}
          secondaryValue={currencyFormatter(treasuryValue * btcCurrentPrice)}
        />
      </div>
    </>
  );
};

export default DashboardAccumulatorInfo;
